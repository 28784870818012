import React, { useCallback, useState } from 'react';
import {
  Form,
  Input,
  Modal,
  message,
} from 'antd';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';

import authApi from 'apis/auth';
import passwordValidator from 'utils/passwordValidation';

type hookType = (userEmail: string) => [() => void, JSX.Element];

const usePasswordModal: hookType = (userEmail) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t } = useTranslation();
  const [changePasswordForm] = Form.useForm();

  const handleReCaptchaVerify = useCallback(async (action: string) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return '';
    }
    const recaptcha = await executeRecaptcha(action);
    return recaptcha;
  }, [executeRecaptcha]);

  const changePassword = async (oldPass: string, newPass: string) => {
    setIsLoading(true);
    const recaptcha = await handleReCaptchaVerify('resendVerificationEmail');
    authApi.updatePassword(userEmail, oldPass, newPass, recaptcha)
      .then(() => {
        message.success('Changed! You will be redirected to the login page in a few seconds');
        setTimeout(() => window.location.assign('/login'), 3000);
      })
      .catch((err) => message.error(err.response.data.message))
      .finally(() => {
        setIsLoading(false);
        setIsVisible(false);
      });
  };

  const PassModal = (
    <Modal
      title={t('signupPage.confirmEmailPage.editEmail')}
      onOk={() => changePasswordForm.submit()}
      visible={isVisible}
      onCancel={() => {
        setIsVisible(false);
        changePasswordForm.resetFields();
      }}
      wrapClassName="modal"
      confirmLoading={isLoading}
    >
      <Form
        layout="vertical"
        requiredMark={false}
        form={changePasswordForm}
        onKeyUp={(e) => { if (e.key === 'Enter') changePasswordForm.submit(); }}
        onFinish={(data) => changePassword(data.currentPassword, data.newPassword)}
      >
        <Form.Item
          className="no-help"
          name="currentPassword"
          label="Current password"
          rules={[{ required: true }]}
        >
          <Input.Password disabled={isLoading} className="password-input" />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label="New password"
          className="with-help"
          help="At least 8 characters + one upper and lowercase letter"
          hasFeedback
          rules={[{
            required: true,
            validator: (_, value) => (
              passwordValidator.validate(value)
                ? Promise.resolve()
                : Promise.reject(new Error(''))
            ),
          }]}
        >
          <Input.Password disabled={isLoading} className="password-input" />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Confirm new password"
          className="no-help"
          dependencies={['newPassword']}
          hasFeedback
          rules={[{ required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            })]}
        >
          <Input.Password disabled={isLoading} className="password-input" />
        </Form.Item>
      </Form>
    </Modal>
  );

  return [() => setIsVisible(true), PassModal];
};

export default usePasswordModal;
