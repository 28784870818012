/* eslint-disable react/require-default-props */
import React from 'react';

import { Col, Row } from 'antd';
import Set from 'views/components/Set/Set';
import { QrCode } from '@sis-lab/web-ui-components';
import { transformDate } from 'utils';

export type PaymentQrCodeDetails = {
  amount: number,
  status: string,
  orderId: string,
  currency: string,
  createdAt: string,
  payeeName: string,
  payeeEmail: string,
  payerEmail?: string,
};

type PaymentQrCodeProps = {
  expanded: boolean,
  prmId: string
  paymentDetails?: PaymentQrCodeDetails
};

const PaymentQrCode = ({
  expanded,
  prmId,
  paymentDetails,
}: PaymentQrCodeProps) => (
  (expanded && paymentDetails)
    ? (
      <div
        style={{ margin: '10px 0' }}
      >
        <div
          style={{ marginBottom: '10px' }}
        >
          <Row>
            <Col span={12}>
              <Set
                label="Amount"
                value={`${paymentDetails.amount} ${paymentDetails.currency}`}
              />
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Set label="OrderId" value={paymentDetails.orderId} />
            </Col>
          </Row>

          <Row>
            <Col span={14}>
              <Set label="Created At" value={transformDate(paymentDetails.createdAt)} />
            </Col>
            <Col span={10} style={{ textAlign: 'right' }}>
              <Set label="Status" value={paymentDetails.status} />
            </Col>
          </Row>

          <Row>
            <Col span={14}>
              <Set label="Payee Email" value={paymentDetails.payeeEmail} />
            </Col>
            <Col span={10} style={{ textAlign: 'right' }}>
              <Set label="Payee Name" value={paymentDetails.payeeName} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Set
                label="Payer Email"
                value={paymentDetails.payerEmail || 'Unassigned'}
              />
            </Col>
          </Row>
        </div>
        <QrCode
          width={300}
          height={300}
            // eslint-disable-next-line no-restricted-globals
          data={`${location.origin}/rtp/receive?prmid=*${prmId}`}
        />
      </div>
    ) : (
      <div
        style={{ margin: '10px 0' }}
      >
        <QrCode
          width={300}
          height={300}
            // eslint-disable-next-line no-restricted-globals
          data={`${location.origin}/rtp/receive?prmid=*${prmId}`}
        />
      </div>
    )
);

export default PaymentQrCode;
