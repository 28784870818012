/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { store, history } from 'store';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import throttle from 'lodash/throttle';
import userManager from 'utils/userManager';
import { saveState } from 'utils/localStorage';

import PaymentsPage from 'views/pages/PaymentsPage/PaymentsPage';
import SignupPage from 'views/pages/SignupPage/SignupPage';
import LoginPage from 'views/pages/LoginPage/LoginPage';
import ForgotPasswordPage from 'views/pages/ForgotPasswordPage/ForgotPasswordPage';
import ResetPasswordPage from 'views/pages/ResetPasswordPage/ResetPasswordPage';
import EmailVerifiedPage from 'views/pages/VerifyEmailPage/VerifyEmailPage';
import ProfilePage from 'views/pages/ProfilePage/ProfilePage';
import PrivateRoute from 'views/components/PrivateRoute';
import PrivateRouter from 'views/components/PrivateRouter';
import MainBoard from 'views/layouts/MainBoard/MainBoard';
import MainLayout from 'views/layouts/MainLayout/MainLayout';
import RtpConfirmationPage from 'views/pages/RtpConfirmationPage/RtpConfirmationPage';
import RtpReceivePage from 'views/pages/RtpReceivePage/RtpReceivePage';
import ShowPrmQrPage from 'views/pages/ShowPrmQrPage/ShowPrmQrPage';
import NoMatch from 'views/pages/NoMatch/NoMatch';
import CallbackPage from './views/pages/CallbackPage';
import LandingPage from './views/pages/LandingPage/LandingPage';

import './i18n/i18n';
import './App.css';

store.subscribe(
  throttle(() => {
    // console.log("SAVING STATE: ", store.getState());
    saveState(store.getState());
  }, 1000),
);

const App = () => (
  <Provider store={store}>
    <OidcProvider store={store} userManager={userManager}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/callback" component={CallbackPage} />
          <Route path="/login" render={() => userManager.signinRedirect()} />
          <Route path="/logout" render={() => userManager.signoutCallback()} />
          {/* <Route path="/login" component={LoginPage} />
        <Route path="/signup" component={SignupPage} /> */}
          {/* <Route path="/verify-email" component={EmailVerifiedPage} />
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          <Route path="/reset-password" component={ResetPasswordPage} /> */}
          <PrivateRouter path="/board">
            <MainBoard>
              <Switch>
                <Route exact path="/board/payments" component={PaymentsPage} />
                <Route exact path="/board/profile" component={ProfilePage} />
                <Route path="/board/transactions" render={() => <div>transactions</div>} />
                <Route path="/board/transactions" render={() => <div>transactions</div>} />
              </Switch>
            </MainBoard>
          </PrivateRouter>
          <PrivateRoute
            exact
            path="/rtp/receive"
            render={() => <MainBoard><RtpReceivePage /></MainBoard>}
          />
          <PrivateRoute
            exact
            path="/rtp/confirm"
            render={() => <MainBoard><RtpConfirmationPage /></MainBoard>}
          />
          <Route
            exact
            path="/"
            render={() => (<MainLayout><LandingPage /></MainLayout>)}
          />
          <Route
            exact
            path="/rtp/show"
            render={() => (<MainLayout><ShowPrmQrPage /></MainLayout>)}
          />
          <Route
            path="*"
            render={() => (<MainLayout><NoMatch /></MainLayout>)}
          />
        </Switch>
      </ConnectedRouter>
    </OidcProvider>
  </Provider>
);

export default App;
