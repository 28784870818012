import { message, Modal } from 'antd';
import React, { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import apiGateway from 'apis/api-gateway';
import userManager from 'utils/userManager';
import EidEasyWidget from '../EidEasyWidget/EidEasyWidget';

type hookType = (userEmail: string) => [() => void, JSX.Element];

// why is it needed?
function useForceUpdate() {
  const [, setValue] = useState<number>(0);
  return () => setValue((val) => val + 1);
}

const useIdentityModal:hookType = (userEmail) => {
  const [isVisible, setIsVisible] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const forceUpdate = useForceUpdate();

  const handleReCaptchaVerify = useCallback(async (action: string) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return '';
    }
    const recaptcha = await executeRecaptcha(action);
    return recaptcha;
  }, [executeRecaptcha]);

  const confirmUserWithEid = async (data: any) => {
    message.loading({
      content: 'Confirming you...',
      key: 123,
    });
    try {
      const token = await handleReCaptchaVerify('confirm_user_eid');
      await apiGateway.confirmUserWithEid(
        userEmail!,
        {
          attributes: {
            gender: data.current_login_method,
            birthdate: data.birth_date,
            locale: data.country,
            personal_identifier: data.idcode,
            is_verified: true,
          },
        },
        token,
      );
      message.success('Successfuly confirmed your identity');

      await userManager.refreshUser();
      forceUpdate();
    } catch (err: any) {
      message.error(`${err.response.data.message}`);
      console.log(err.response.data.message);
    } finally {
      message.destroy(123);
      setIsVisible(false);
    }
  };

  const IdentityModal = (
    <Modal
      title="Confirm your identity'"
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
      wrapClassName="modal"
      footer={null}
    >
      <EidEasyWidget
        onSuccess={(data: any) => confirmUserWithEid(data)}
      />
    </Modal>
  );

  return [() => setIsVisible(true), IdentityModal];
};

export default useIdentityModal;
