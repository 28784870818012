import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Layout } from 'antd';
// import { FileDoneOutlined, FileSearchOutlined } from '@ant-design/icons';

import config from 'config';

import SideMenu from 'views/components/SideMenu/SideMenu';
import CustomHeader from 'views/components/Header/Header';

import './MainBoard.scss';

import Breadcrumb from 'views/components/Breadcrumb/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { EuroOutlined, UserOutlined } from '@ant-design/icons';

const { Content, Footer } = Layout;

const MainBoard: React.FC = ({ children }: any) => {
  const [t] = useTranslation();
  const location = useLocation();
  const [chosenItem, setChosenItem] = useState<any>();

  const SideMenuItems = [
    {
      name: `${t('navbar.payments')}`,
      route: '/board/payments',
      icon: <EuroOutlined />,
    },
    {
      name: `${t('navbar.profile')}`,
      route: '/board/profile',
      icon: <UserOutlined />,
    },
    // {
    //   name: `${t('paymentsPage.menu.signatures')}`,
    //   route: '/board/signatures',
    //   icon: <FileDoneOutlined />,
    // },
  ];

  const parseURL = (url: string) => {
    if (url.match('board/documents/.+/signatures')) {
      return '/board/signatures';
    }
    return url;
  };

  useEffect(() => {
    setChosenItem(SideMenuItems.find((item) => parseURL(location.pathname).includes(item.route))?.route || '');
  }, [location]);

  return (
    <div id="MainBoard">
      <Layout style={{ minHeight: '100vh' }}>
        <SideMenu
          items={SideMenuItems}
          chosenItem={chosenItem}
        />
        <Layout className="site-layout">
          <CustomHeader />
          <div
            style={{
              maxWidth: '1240px',
              margin: '0 auto',
              width: '100%',
            }}
          >
            <Breadcrumb />
            <Content style={{ margin: '16px 16px' }}>{children}</Content>
          </div>
          <Footer style={{ textAlign: 'center' }}>
            {t('footer.logo')}
            {' '}
            {new Date().getFullYear()}
            {' '}
            {t('footer.text')}
          </Footer>
          <div className="version">{`${config.projectCommitDate} ${config.projectCommitShortSHA}`}</div>
        </Layout>
      </Layout>
    </div>
  );
};

export default MainBoard;
