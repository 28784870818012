/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import { ApiToken, NewApiToken } from 'types';
import {
  deleteApiTokenService,
  generateApiTokenService,
  getApiTokensListService,
  updateApiTokenService,
} from 'services/prm';
import BlockHeader from 'views/components/BlockHeader/BlockHeader';
import { PlusOutlined } from '@ant-design/icons';
import { Button, message, Tooltip } from 'antd';
import { downloadBlobFile } from 'utils';
import { useTranslation } from 'react-i18next';
import {
  DeleteTokenModal,
  EditTokenNameModal,
  GenerateNewTokenModal,
  ShowNewTokenModal,
} from './modals';
import ApiTokensTable from './ApiTokensTable/ApiTokensTable';

const ApiTokens = () => {
  const { t } = useTranslation();
  const [tokens, setTokens] = useState<ApiToken[]>([]);
  const [newToken, setNewToken] = useState('');
  const [currentToken, setCurrentToken] = useState('');
  const [tokensLoading, setTokensLoading] = useState(false);
  const [deleteTokenLoading, setDeleteTokenLoading] = useState(false);
  const [editTokenNameLoading, setEditTokenNameLoading] = useState(false);
  const [generateNewTokenLoading, setGenerateNewTokenLoading] = useState(false);
  const [changeTokenStatusLoading, setChangeTokenStatusLoading] = useState(false);

  const [deleteTokenModalVisible, setDeleteTokenModalVisible] = useState(false);
  const [showNewTokenModalVisible, setShowNewTokenModalVisible] = useState(false);
  const [editTokenNameModalVisible, setEditTokenNameModalVisible] = useState(false);
  const [generateNewTokenModalVisible, setGenerateNewTokenModalVisible] = useState(false);

  const fetchTokens = async () => {
    setTokensLoading(true);
    try {
      const apiTokensList = await getApiTokensListService();
      setTokens(apiTokensList.tokens);
    } catch (err) {
      console.log('some error during token fetch occurred');
    } finally {
      setTokensLoading(false);
    }
  };

  const generateNewToken = async (tokenName: string) => {
    try {
      setGenerateNewTokenLoading(true);
      setGenerateNewTokenModalVisible(false);
      const newTokenResponse: NewApiToken = await generateApiTokenService(tokenName);
      setNewToken(newTokenResponse.accessToken);
      setCurrentToken(newTokenResponse.id);
      await fetchTokens();
      setShowNewTokenModalVisible(true);
    } catch (err: any) {
      const {
        status,
        data: { message: errMessage },
      } = err.response;
      message.error(`${status}: ${errMessage}`);
    } finally {
      setGenerateNewTokenLoading(false);
    }
  };

  const changeApiTokenStatus = async (id: string, action: 'pause' | 'resume') => {
    try {
      setChangeTokenStatusLoading(true);
      await updateApiTokenService(id, undefined, action === 'pause');
      await fetchTokens();
    } catch (err) {
      console.log(err);
    } finally {
      setChangeTokenStatusLoading(false);
    }
  };

  const deleteToken = async (id: string) => {
    try {
      setDeleteTokenLoading(true);
      await deleteApiTokenService(id);
      await fetchTokens();
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteTokenModalVisible(false);
      setDeleteTokenLoading(false);
    }
  };

  const editTokenName = async (id: string, name: string) => {
    try {
      setEditTokenNameLoading(true);
      await updateApiTokenService(id, name);
      await fetchTokens();
    } catch (err) {
      console.log(err);
    } finally {
      setEditTokenNameModalVisible(false);
      setEditTokenNameLoading(false);
    }
  };

  const downloadNewToken = () => {
    const foundToken = tokens.filter((token) => token.id === currentToken)[0];
    const tokenBlob = new Blob([newToken], {
      type: 'text/plain',
    });
    if (foundToken) downloadBlobFile(tokenBlob, `${foundToken.name}.txt`);
    else message.error('Sorry, some error occurred');
  };

  useEffect(() => {
    fetchTokens();
  }, []);

  return (
    <div>
      <BlockHeader title="API Tokens">
        <Tooltip placement="bottom" title={t('profilePage.table.actionTooltips.add')}>
          <Button
            shape="circle"
            type="primary"
            disabled={tokensLoading}
            loading={generateNewTokenLoading}
            onClick={() => setGenerateNewTokenModalVisible(true)}
            icon={<PlusOutlined />}
          />
        </Tooltip>
      </BlockHeader>
      <ApiTokensTable
        items={tokens}
        loading={tokensLoading}
        deleteButtonsBlocked={deleteTokenLoading}
        editNameButtonsBlocked={editTokenNameLoading}
        changeStatusButtonsBlocked={changeTokenStatusLoading}
        pauseToken={(id) => changeApiTokenStatus(id, 'pause')}
        resumeToken={(id) => changeApiTokenStatus(id, 'resume')}
        showDeleteTokenModal={(id: string) => {
          setCurrentToken(id);
          setDeleteTokenModalVisible(true);
        }}
        showEditTokenNameModal={(id: string) => {
          setCurrentToken(id);
          setEditTokenNameModalVisible(true);
        }}
      />
      <ShowNewTokenModal
        token={newToken}
        visible={showNewTokenModalVisible}
        closeModal={() => {
          setNewToken('');
          setCurrentToken('');
          setShowNewTokenModalVisible(false);
        }}
        downloadToken={downloadNewToken}
      />
      <GenerateNewTokenModal
        loading={generateNewTokenLoading}
        visible={generateNewTokenModalVisible}
        invalidTokenNames={tokens.map((token) => token.name)}
        closeModal={() => setGenerateNewTokenModalVisible(false)}
        generateNewToken={(name: string) => generateNewToken(name)}
      />
      <EditTokenNameModal
        loading={editTokenNameLoading}
        visible={editTokenNameModalVisible}
        invalidTokenNames={tokens.map((token) => token.name)}
        closeModal={() => setEditTokenNameModalVisible(false)}
        editTokenName={(name: string) => editTokenName(currentToken, name)}
      />
      <DeleteTokenModal
        loading={deleteTokenLoading}
        visible={deleteTokenModalVisible}
        closeModal={() => setDeleteTokenModalVisible(false)}
        deleteToken={() => deleteToken(currentToken)}
      />
    </div>
  );
};

export default ApiTokens;
