/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { Divider } from 'antd';

import { User } from 'oidc-client';
import { IRouterProps } from 'types/router';

import { ApplicationState } from 'modules';
import { BreadcrumbsAction } from 'modules/breadcrumbs/types';
import { IBreadcrumbsAction, getBreadcrumbsAction } from 'modules/breadcrumbs/actions';

import UserData from './components/UserData/UserData';
import ProfileSettings from './components/ProfileSettings/ProfileSettings';
import ApiTokens from './components/ApiTokens/ApiTokens';

interface IProfilePageProps {
  user: User | undefined;
  getBreadcrumbsActionDispatch: (path: string, lastBreadcrumb: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch<BreadcrumbsAction>) => ({
  getBreadcrumbsActionDispatch: (path: string, lastBreadcrumb: string) => {
    getBreadcrumbsAction(path, lastBreadcrumb)(dispatch);
  },
});

const mapStateToProps = (state: ApplicationState) => ({
  user: state.oidc.user,
});

type Props = IProfilePageProps & IRouterProps & IBreadcrumbsAction;

const ProfilePage = ({ user, location, getBreadcrumbsActionDispatch }: Props) => {
  useEffect(() => {
    getBreadcrumbsActionDispatch(location.pathname, '');
  }, []);

  return (
    <div className="site-layout-background">
      <UserData user={user} />
      <Divider />
      <ProfileSettings />
      <Divider />
      <ApiTokens />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
