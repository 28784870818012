/* eslint-disable curly */
/* eslint-disable nonblock-statement-body-position */
/* eslint-disable no-nested-ternary */

import React, { useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useTranslation } from 'react-i18next';

import {
  Tooltip, Table, Button, message, Form, Modal,
} from 'antd';

import {
  DownloadOutlined,
  InfoCircleOutlined,
  MailOutlined,
  QrcodeOutlined,
  BookOutlined,
  EuroOutlined,
  DollarOutlined,
} from '@ant-design/icons';

import { downloadBlobFile, transformDate } from 'utils';

import { IPrm, IPrmStatus, IPrmDocument } from 'types/prm';

import {
  getPrmDocumentService,
  getPrmService,
  initiatePrmService,
  notifyPayerService,
} from 'services/prm';

import { getRtpMessagesListService } from 'services/rtp';

import BlockHeader from '../BlockHeader/BlockHeader';
import { PaymentQrCodeDetails } from '../PaymentQrCode/PaymentQrCode';

import './PaymentsTable.scss';
import NotifyPayerModal from './components/NotifyPayerModal';
import StatusHistoryModal from './components/StatusHistoryModal';
import QrCodeModal from './components/QrCodeModal';
import RtpMessagesHistory from '../RtpMessagesHistory/RtpMessagesHistory';
import PaymentStatusBadge from '../PaymentStatusBadge/PaymentStatusBadge';

type PaymentsTableProps = {
  items: IPrm[];
  loading: boolean;
};

type PrmTableItem = {
  id: string;
  payee: {
    name: string;
    email?: string;
    id?: string;
  };
  payer: {
    name: string;
    email?: string;
    id?: string;
  };
  invitee?: {
    name: string;
    email: string;
    id?: string;
  };
  amount: number;
  currency: string;
  status: IPrmStatus;
  orderId: string;
  description: string;
  createdAt: string;
  document?: IPrmDocument;
  xpryDt?: string;
  reqdExctnDt?: string;
};

const statusSortOrder = {
  unknown: 0,
  created: 1,
  received: 2,
  rejected: 3,
  accepted: 4,
  started: 5,
  canceled: 6,
  payed: 7,
};

const PaymentsTable = ({ items, loading }: PaymentsTableProps) => {
  const { t } = useTranslation();
  const [qrCodeError, setQrCodeError] = useState<boolean>(false);
  const [qrCodeLoading, setQrCodeLoading] = useState<boolean>(false);
  const [documentLoading, setDocumentLoading] = useState<boolean>(false);
  const [rtpMessagesLoading, setRtpMessagesLoading] = useState<boolean>(false);
  const [initiatePaymentLoading, setInitiatePaymentLoading] = useState<boolean>(false);

  const [qrModalVisible, setQrModalVisible] = useState<boolean>(false);
  const [notifyPayerModalVisible, setNotifyPayerModalVisible] = useState<boolean>(false);
  const [statusHistoryModalVisible, setStatusHistoryModalVisible] = useState<boolean>(false);
  const [rtpMessagesModalVisible, setRtpMessagesModalVisible] = useState<boolean>(false);

  const { width, ref } = useResizeDetector();
  const [notifyPayerForm] = Form.useForm();
  const [paymentDetails, setPaymentDetails] = useState<PaymentQrCodeDetails>();
  const [selectedPrmId, setSelectedPrmId] = useState<string>('');
  const [notifyPayerLoading, setNotifyPayerLoading] = useState<boolean>(false);
  const [rtpMessages, setRtpMessages] = useState<object>();

  const MAX_NOTE_LENGTH = 50;
  const MAX_CUSTOMER_LENGTH = 30;
  // const [apiTokenStatusChangingIdLoading, apiTokenStatusChangingIdLoading] = useState(second)
  const data: PrmTableItem[] = items.map((item) => ({
    id: item.id,
    payee: (() => {
      if (!item.payee) return { name: t('paymentsPage.table.absentInfo.unknown') };
      if (!item.payee.email)
        return { ...item.payee, email: t('paymentsPage.table.absentInfo.noEmail') };
      if (!item.payee.name)
        return { ...item.payee, name: t('paymentsPage.table.absentInfo.noName') };
      return item.payee;
    })(),
    payer: (() => {
      if (!item.payer) return { name: t('paymentsPage.table.absentInfo.unassigned') };
      if (!item.payer.email)
        return { ...item.payer, email: t('paymentsPage.table.absentInfo.noEmail') };
      if (!item.payer.name)
        return { ...item.payer, name: t('paymentsPage.table.absentInfo.noName') };
      return item.payer;
    })(),
    invitee: item.invitee,
    amount: item.amount,
    status: item.status ? item.status : { status: 'unknown' },
    orderId: item.orderId,
    currency: item.currency,
    description: item.description,
    document: item.document,
    xpryDt: item.xpryDt ? transformDate(item.xpryDt, {
      showYear: width ? width > 109 : false,
      showSeconds: false,
    }) : '',
    reqdExctnDt: item.reqdExctnDt ? transformDate(item.reqdExctnDt, {
      showYear: width ? width > 109 : false,
      showSeconds: false,
    }) : '',
    createdAt: transformDate(item.createdAt, {
      showYear: width ? width > 109 : false,
      showSeconds: false,
    }),
  }));

  const columns: any = [
    {
      title: t('paymentsPage.table.headers.order'),
      key: 'createdAt',
      width: 275,
      render: (rowProps: PrmTableItem) => (
        <span>
          <div ref={ref}>{rowProps.createdAt}</div>
          <div>{rowProps.orderId}</div>
        </span>
      ),
      sorter: (a: PrmTableItem, b: PrmTableItem) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    },
    {
      title: t('paymentsPage.table.headers.payee'),
      key: 'payee',
      width: 350,
      responsive: ['md'],
      render: (rowProps: PrmTableItem) => (
        <span>
          <div>
            {rowProps.payee.name.length > MAX_CUSTOMER_LENGTH ? (
              <Tooltip placement="bottomLeft" title={`${rowProps.payee.name}`}>
                {`${rowProps.payee.name.substring(0, MAX_CUSTOMER_LENGTH - 3)}...`}
              </Tooltip>
            ) : (
              rowProps.payee.name
            )}
          </div>
          {rowProps.payee.email ? (
            <div>
              {rowProps.payee.email.length > MAX_CUSTOMER_LENGTH ? (
                <Tooltip placement="bottomLeft" title={`${rowProps.payee.email}`}>
                  {`${rowProps.payee.email.substring(0, MAX_CUSTOMER_LENGTH - 3)}...`}
                </Tooltip>
              ) : (
                rowProps.payee.email
              )}
            </div>
          ) : (
            ''
          )}
        </span>
      ),
      sorter: (a: PrmTableItem, b: PrmTableItem) => a.payee.name.localeCompare(b.payee.name),
      onFilter: (value: string, rowProps: PrmTableItem) => rowProps.payee.name === value,
      filters: [...new Set(data.map((item) => item.payee.name))].map((item) => ({
        text: item,
        value: item,
      })),
    },
    {
      title: t('paymentsPage.table.headers.payer'),
      key: 'payer',
      width: 300,
      responsive: ['sm'],
      render: (rowProps: PrmTableItem) => (
        <span>
          <div>
            {rowProps.payer!.name.length > MAX_CUSTOMER_LENGTH ? (
              <Tooltip placement="bottomLeft" title={`${rowProps.payer!.name}`}>
                {`${rowProps.payer!.name.substring(0, MAX_CUSTOMER_LENGTH - 3)}...`}
              </Tooltip>
            ) : (
              rowProps.payer!.name
            )}
          </div>
          {rowProps.payer!.email ? (
            <div>
              {rowProps.payer!.email.length > MAX_CUSTOMER_LENGTH ? (
                <Tooltip placement="bottomLeft" title={`${rowProps.payer!.email}`}>
                  {`${rowProps.payer!.email.substring(0, MAX_CUSTOMER_LENGTH - 3)}...`}
                </Tooltip>
              ) : (
                rowProps.payer!.email
              )}
            </div>
          ) : (
            ''
          )}
        </span>
      ),
      sorter: (a: PrmTableItem, b: PrmTableItem) => a.payer!.name.localeCompare(b.payer!.name),
      onFilter: (value: string, rowProps: PrmTableItem) => rowProps.payer!.name === value,
      filters: [...new Set(data.map((item) => item.payer.name))].map((item) => ({
        text: item,
        value: item,
      })),
    },
    {
      title: t('paymentsPage.table.headers.description'),
      key: 'description',
      width: 400,
      responsive: ['lg'],
      render: (rowProps: PrmTableItem) => (rowProps.description ? (
        rowProps.description.length > MAX_NOTE_LENGTH ? (
          <Tooltip placement="bottomLeft" title={`${rowProps.description}`}>
            {`${rowProps.description.substring(0, MAX_NOTE_LENGTH - 3)}...`}
          </Tooltip>
        ) : (
          rowProps.description
        )
      ) : (
        ''
      )),
      sorter: (a: PrmTableItem, b: PrmTableItem) => a.description.localeCompare(b.description),
    },
    {
      title: t('paymentsPage.table.headers.sum'),
      key: 'amount',
      width: 150,
      render: (rowProps: PrmTableItem) => <span>{`${rowProps.amount} ${rowProps.currency}`}</span>,
      sorter: (a: PrmTableItem, b: PrmTableItem) => a.amount - b.amount,
    },
    {
      title: t('paymentsPage.table.headers.status'),
      key: 'status',
      width: 150,
      render: (rowProps: PrmTableItem) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <span style={{ display: 'inline-block', width: '100%', textAlign: 'right' }}>
            {t(`payment.status.${rowProps.status.status}`)}
          </span>
          <PaymentStatusBadge status={rowProps.status.status} />
          {rowProps.status.status === 'unknown' ? (
            ''
          ) : (
            <InfoCircleOutlined
              onClick={(e) => {
                e.stopPropagation();
                setSelectedPrmId(rowProps.id);
                setStatusHistoryModalVisible(true);
              }}
            />
          )}
        </div>
      ),
      filters: [...new Set(data.map((item) => item.status.status))].map((item) => ({
        text: item,
        value: item,
      })),
      onFilter: (value: string, rowProps: PrmTableItem) => rowProps.status.status === value,
      sorter: (a: PrmTableItem, b: PrmTableItem) => statusSortOrder[a.status.status] - statusSortOrder[b.status.status],
    },
  ];

  const initiatePrm = async (prmId: string, gateway: 'KEVIN' | 'MONTONIO') => {
    console.log(gateway);
    setInitiatePaymentLoading(true);
    message.loading({
      content: 'starting payment process...',
      key: 123,
    });
    try {
      const prmInitResponse = await initiatePrmService({
        prmId,
        gateway,
        // kevinRedirectUrl: `https://status.gw.ino-pay.com/?prmid=${prmId}`,
      });
      if (prmInitResponse.gatewayLink) window.open(prmInitResponse.gatewayLink, '_blank');
      // window.location.href = prmInitResponse.gatewayLink;
    } catch (error: any) {
      console.log('error during init');
      console.log(error);
      console.log(error.response);
      console.log(error.response.data);
      message.error(
        error.response.data.message
          || (typeof error.response.data.data === 'string' ? error.response.data.data : null)
          || error.response.data.error.description
          || error.message,
        1.5,
      );
      // console.log(error.response.data);
    } finally {
      setInitiatePaymentLoading(false);
      message.destroy(123);
    }
  };

  const notifyPayer = async (formData: any) => {
    setNotifyPayerLoading(true);
    message.loading({
      key: 111,
      content: 'Sending email',
    });
    try {
      await notifyPayerService({
        prmId: selectedPrmId,
        recipient: {
          name: formData.payerName,
          email: formData.payerEmail,
        },
      });
      message.success('Payer successfully notified');
    } catch (err: any) {
      message.error(err);
    } finally {
      message.destroy(111);
      notifyPayerForm.resetFields();
      setNotifyPayerLoading(false);
      setNotifyPayerModalVisible(false);
    }
  };

  const showQrCode = async (prmId: string) => {
    setQrCodeLoading(true);
    setQrCodeError(false);
    try {
      const prm = await getPrmService(prmId);
      const {
        payee, payer, amount, status, orderId, currency, createdAt,
      } = prm;
      setPaymentDetails({
        amount,
        status: status.status,
        orderId,
        currency,
        createdAt,
        payeeName: payee.name,
        payeeEmail: payee.email,
        payerEmail: payer?.email,
      });
      setQrModalVisible(true);
    } catch (err) {
      // console.log(err);
      setQrCodeError(true);
      message.error('Some error occurred, try again');
    } finally {
      setQrCodeLoading(false);
    }
  };

  const showRtpMessagesHistory = async (prmId: string) => {
    setRtpMessagesLoading(true);
    try {
      const rtpMessagesResponse = await getRtpMessagesListService(prmId);
      // console.log(rtpMessagesResponse);
      setRtpMessages(rtpMessagesResponse);
      setRtpMessagesModalVisible(true);
    } catch (err) {
      // console.log(err);
      message.error('Some error occurred, try again');
    } finally {
      setRtpMessagesLoading(false);
    }
  };

  const renderExpandedPaymentRow = (prm: PrmTableItem) => (
    <div>
      <BlockHeader title={`${prm.orderId}`}>
        <QrCodeModal
          prmId={prm.id}
          qrCodeError={qrCodeError}
          qrCodeLoading={qrCodeLoading}
          title={paymentDetails?.orderId}
          paymentDetails={paymentDetails}
          qrModalVisible={qrModalVisible}
          closeModal={() => setQrModalVisible(false)}
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip placement="bottom" title="Initiate Kevin payment">
            <Button
              shape="circle"
              type="primary"
              onClick={() => initiatePrm(prm.id, 'KEVIN')}
              disabled={initiatePaymentLoading}
              icon={<EuroOutlined />}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Initiate Montonio payment">
            <Button
              shape="circle"
              type="primary"
              onClick={() => initiatePrm(prm.id, 'MONTONIO')}
              disabled={initiatePaymentLoading}
              icon={<DollarOutlined />}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Notify payer">
            <Button
              shape="circle"
              type="primary"
              onClick={() => {
                setSelectedPrmId(prm.id);
                setNotifyPayerModalVisible(true);
              }}
              icon={<MailOutlined />}
            />
          </Tooltip>
          {prm.document ? (
            <Tooltip placement="bottom" title="Download document">
              <Button
                shape="circle"
                type="primary"
                disabled={documentLoading}
                icon={<DownloadOutlined />}
                onClick={async () => {
                  setDocumentLoading(true);
                  try {
                    const [fileData, fileName] = await getPrmDocumentService(prm.id);
                    if (fileData && fileName) downloadBlobFile(fileData, fileName);
                    else message.error('Some error occurred during download');
                  } catch (err) {
                    // console.log(err);
                    message.error("This document can't be downloaded now");
                  } finally {
                    setDocumentLoading(false);
                  }
                }}
              />
            </Tooltip>
          ) : (
            false
          )}
          <Tooltip placement="bottom" title="Show messages">
            <Button
              shape="circle"
              type="primary"
              onClick={() => showRtpMessagesHistory(prm.id)}
              disabled={rtpMessagesLoading}
              icon={<BookOutlined />}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Show QR">
            <Button
              shape="circle"
              type="primary"
              onClick={() => showQrCode(prm.id)}
              disabled={qrCodeLoading}
              icon={<QrcodeOutlined />}
            />
          </Tooltip>
        </div>
      </BlockHeader>
      <p>Payment Details:</p>
      <ul>
        <li>{`Amount: ${prm.amount} ${prm.currency}`}</li>
        <li>{`Created at: ${prm.createdAt}`}</li>
        {!!prm.reqdExctnDt && <li>{`Requested execution date: ${prm.reqdExctnDt}`}</li>}
        {!!prm.xpryDt && <li>{`Expiry date: ${prm.xpryDt}`}</li>}
        <li>{`Status: ${prm.status.status}`}</li>
        <li>{`Id: ${prm.id}`}</li>
        <li>{`Description: ${prm.description}`}</li>
      </ul>
      <p>Payee Details:</p>
      <ul>
        {prm.payee.id && <li>{`Id: ${prm.payee.id}`}</li>}
        <li>{`Name: ${prm.payee.name}`}</li>
        {!prm.payee.email || <li>{`Email: ${prm.payee.email}`}</li>}
      </ul>
      {!(
        prm.invitee
        && prm.invitee.email !== prm.payer.email
        && prm.invitee.id !== prm.payer.id
      ) || (
        <>
          <p>Invitee Details:</p>
          <ul>
            {prm.invitee.id && <li>{`Id: ${prm.invitee.id}`}</li>}
            <li>{`Name: ${prm.invitee.name}`}</li>
            <li>{`Email: ${prm.invitee.email}`}</li>
          </ul>
        </>
      )}
      {!(prm.payer.name !== t('paymentsPage.table.absentInfo.unassigned')) || (
        <>
          <p>Payer Details:</p>
          <ul>
            {prm.payer.id && <li>{`Id: ${prm.payer.id}`}</li>}
            <li>{`Name: ${prm.payer.name}`}</li>
            {!prm.payer.email || <li>{`Email: ${prm.payer.email}`}</li>}
          </ul>
        </>
      )}
    </div>
  );

  return (
    <div>
      <NotifyPayerModal
        notifyPayerForm={notifyPayerForm}
        notifyPayerLoading={notifyPayerLoading}
        notifyPayerModalOpen={notifyPayerModalVisible}
        notifyPayer={(formData) => notifyPayer(formData)}
        closeModal={() => setNotifyPayerModalVisible(false)}
      />
      <StatusHistoryModal
        selectedPrmId={selectedPrmId}
        statusHistoryModalVisible={statusHistoryModalVisible}
        closeModal={() => setStatusHistoryModalVisible(false)}
      />
      <Modal
        visible={rtpMessagesModalVisible}
        onCancel={() => setRtpMessagesModalVisible(false)}
        footer={null}
      >
        <RtpMessagesHistory rtpMessages={rtpMessages} />
      </Modal>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        pagination={false}
        className="card-table"
        locale={{
          triggerDesc: t('paymentsPage.table.sortingTooltips.descending'),
          triggerAsc: t('paymentsPage.table.sortingTooltips.ascending'),
          cancelSort: t('paymentsPage.table.sortingTooltips.cancel'),
        }}
        rowClassName="card-table-row card-table-row--expandable"
        rowKey={(record: PrmTableItem) => record.id}
        expandable={{
          expandedRowRender: (record) => renderExpandedPaymentRow(record),
          expandIcon: () => '',
          columnWidth: 0,
          expandRowByClick: true,
        }}
      />
    </div>
  );
};

export default PaymentsTable;
