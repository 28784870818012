import { AxiosResponse } from 'axios';

import {
  instance,
  setAccessToken,
} from './instance';

const getRtpMessage = (
  messageId: string,
): Promise<AxiosResponse<string>> => instance.get(`/rtp/${messageId}`, { params: { raw: true }, responseType: 'arraybuffer' });

const rtpApi = {
  getRtpMessage,
  setAccessToken,
};

export default rtpApi;
