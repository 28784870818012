import React from 'react';
import {
  Modal, Button, Form, Input,
} from 'antd';

type NotifyPayerModalProps = {
  closeModal: () => void;
  notifyPayer: (formData: any) => void;

  notifyPayerForm: any;
  notifyPayerLoading: boolean;
  notifyPayerModalOpen: boolean;
};

const NotifyPayerModal = ({
  closeModal,
  notifyPayer,
  notifyPayerForm,
  notifyPayerLoading,
  notifyPayerModalOpen,
}: NotifyPayerModalProps) => (
  <Modal
    title="Notify payer"
    visible={notifyPayerModalOpen}
    onCancel={closeModal}
    footer={[
      <Button
        key="submit"
        htmlType="submit"
        form="notifyPayerForm"
        className="button-round"
        loading={notifyPayerLoading}
      >
        Notify
      </Button>,
    ]}
  >
    <Form
      hideRequiredMark
      layout="horizontal"
      id="notifyPayerForm"
      form={notifyPayerForm}
      onFinish={notifyPayer}
    >
      <Form.Item
        label="Payer name"
        name="payerName"
        className="no-help"
        rules={[{ required: true }]}
        help="How do you want us to address the recipient"
      >
        <Input
          disabled={notifyPayerLoading}
          style={{ width: '100%' }}
        />
      </Form.Item>
      <Form.Item
        label="Payer email"
        name="payerEmail"
        className="no-help"
        help="We will send a message to this email"
        rules={[
          {
            required: true,
            type: 'email',
          },
        ]}
      >
        <Input
          disabled={notifyPayerLoading}
          style={{ width: '100%' }}
          type="email"
        />
      </Form.Item>
    </Form>
  </Modal>
);

export default NotifyPayerModal;
