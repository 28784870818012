import { AxiosResponse } from 'axios';
import {
  NewApiToken,
} from 'types';
import { IPrmStatus } from 'types/prm';
import {
  IInitPrmRequest,
  IGetPrmResponse,
  IPrmInitResponse,
  IGeneratePrmRequest,
  IGeneratePrmResponseRequest,
  IGetPrmListResponse,
  INotifyPayerRequest,
  GetApiTokensListResponse,
} from 'types/requests';
import {
  instance,
  setAccessToken,
} from './instance';

const getPrm = (
  prmId: string,
): Promise<AxiosResponse<IGetPrmResponse>> => instance.get(`/prm/${prmId}`);

const getPrmList = (
  search: undefined | string = undefined,
): Promise<AxiosResponse<IGetPrmListResponse>> => instance.get(
  '/prm',
  {
    params: {
      search: search || undefined,
    },
  },
);

const getPrmId = (
  prmIdRequest: IGeneratePrmRequest,
): Promise<AxiosResponse<any>> => instance.post('/prm', prmIdRequest);

const getPrmQrCode = (
  prmId: string,
): Promise<AxiosResponse<string>> => instance.get(`/prm/${prmId}/qr`, { responseType: 'arraybuffer' });

const getPrmStatus = (
  prmId: string,
): Promise<AxiosResponse<IPrmStatus>> => instance.get(`/prm/${prmId}/status`, { params: { history: false } });

const getPrmStatusHistory = (
  prmId: string,
): Promise<AxiosResponse<IPrmStatus[]>> => instance.get(`/prm/${prmId}/status`, { params: { history: true } });

const getPrmDocument = (
  prmId: string,
): Promise<AxiosResponse<string>> => instance.get(`/prm/${prmId}/document`, { responseType: 'arraybuffer' });

const initiatePrm = (
  {
    prmId,
    bankId,
    kevinAccessToken,
    kevinRedirectUrl,
  }: IInitPrmRequest,
): Promise<AxiosResponse<IPrmInitResponse>> => {
  const bankIdParam = bankId ? { bankId } : {};
  const headers = {
    'access-token': kevinAccessToken || '',
    'redirect-url': kevinRedirectUrl || '',
  };
  return instance.post(
    `/prm/${prmId}/init`,
    {},
    {
      params: bankIdParam,
      headers,
    },
  );
};

const generatePrmResponse = (
  {
    prmId,
    status,
    notes,
  }: IGeneratePrmResponseRequest,
): Promise<AxiosResponse<any>> => instance.post(`/prm/${prmId}/response`, {
  status,
  notes,
});

const notifyPayer = ({
  prmId,
  recipient,
}: INotifyPayerRequest): Promise<AxiosResponse<any>> => instance.post('/mail/notify-payer', {
  prmId,
  recipient,
});

const getRtpMessagesList = (
  prmId: string,
): Promise<AxiosResponse<any>> => instance.get(`/prm/${prmId}/rtp`);

const getApiTokensList = ()
: Promise<AxiosResponse<GetApiTokensListResponse>> => (
  instance.get('/tokens')
);

const generateApiToken = (
  tokenName: string,
): Promise<AxiosResponse<NewApiToken>> => (
  instance.post('/tokens', {
    name: tokenName,
  })
);

const updateApiToken = (
  tokenId: string,
  name?: string,
  paused?: boolean,
): Promise<AxiosResponse<any>> => (
  instance.put(`/tokens/${tokenId}`, {
    name,
    paused,
  })
);

const deleteApiToken = (
  tokenId: string,
): Promise<AxiosResponse<any>> => (
  instance.delete(`/tokens/${tokenId}`)
);

const prmApi = {
  getPrm,
  getPrmId,
  getPrmList,
  initiatePrm,
  getPrmQrCode,
  getPrmDocument,
  getPrmStatus,
  setAccessToken,
  generatePrmResponse,
  getPrmStatusHistory,
  notifyPayer,
  getRtpMessagesList,
  getApiTokensList,
  generateApiToken,
  updateApiToken,
  deleteApiToken,
};

export default prmApi;
