import { ApplicationState } from 'modules';
import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ActionTypes, BreadcrumbsAction } from './types';

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export interface IBreadcrumbsAction {
  getBreadcrumbsAction: (path: string, lastBreadcrumb: string) => void;
  clearBreadcrumbsAction: () => void
}

export const getBreadcrumbsAction = (
  path: string,
  lastBreadcrumb: string,
) => (dispatch: Dispatch<BreadcrumbsAction>) => {
  const breadcrumbs: any = {};
  let previousUrl: string;

  const pathSnippets = path.split('/').filter((i) => i);

  if (path.match('board/documents/.+/signatures/.+')) {
    const baseUrl = `/${pathSnippets.slice(0, -1).join('/')}`;
    breadcrumbs[baseUrl] = baseUrl;
    if (lastBreadcrumb) {
      const endUrl = `/${pathSnippets.slice(pathSnippets.length - 1, pathSnippets.length)}`;
      breadcrumbs[endUrl] = lastBreadcrumb;
    }
  } else {
    pathSnippets.forEach((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
      if (!breadcrumbs[url]) {
        const splitted = url.split('/');
        const value = splitted[splitted.length - 1];
        if (value !== 'board' && previousUrl !== '/board/documents') {
        // breadcrumbs[url] = value.charAt(0).toUpperCase() + value.slice(1);
          breadcrumbs[url] = value;
        } else if (value !== 'board' && lastBreadcrumb) {
          breadcrumbs[url] = lastBreadcrumb;
        }
      }
      previousUrl = url;
    });
  }
  return dispatch({ type: ActionTypes.GET_BREADCRUMBS, breadcrumbs });
};

export const clearBreadcrumbsAction = () => (dispatch: Dispatch<BreadcrumbsAction>) => {
  dispatch({ type: ActionTypes.CLEAR_BREADCRUMBS });
};
