import { ActionTypes, BreadcrumbsAction, BreadcrumbsState } from './types';

const initialState: BreadcrumbsState = {
  breadcrumbs: {},
};

const breadcrumbs = (
  state = initialState,
  action: BreadcrumbsAction,
): BreadcrumbsState => {
  switch (action.type) {
    case ActionTypes.GET_BREADCRUMBS: {
      return {
        breadcrumbs: action.breadcrumbs,
      };
    }
    case ActionTypes.CLEAR_BREADCRUMBS: {
      return {
        breadcrumbs: {},
      };
    }
    default:
      return state;
  }
};

export default breadcrumbs;
