import { DownOutlined, UpOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import styles from './CollapsableSection.module.scss';

interface Props {
  title: string
  children: JSX.Element | JSX.Element[]
  className?: string
}

const CollapsableSection = ({ title, children, className }: Props) => {
  const [isClosed, setIsClosed] = useState(false);

  const toogle = () => setIsClosed((state) => !state);

  return (
    <section className={styles.collapsableSection}>
      <div className={styles.controls}>
        <h2>{title}</h2>
        {isClosed ? <UpOutlined onClick={toogle} /> : <DownOutlined onClick={toogle} />}
      </div>
      <div className={isClosed ? styles.isClosed : className}>
        {children}
      </div>
    </section>
  );
};

CollapsableSection.defaultProps = {
  className: undefined,
};

export default CollapsableSection;
