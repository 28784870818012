const {
  REACT_APP_PROJECT_COMMIT_DATE,
  REACT_APP_PROJECT_COMMIT_SHORT_SHA,
  REACT_APP_STORAGE_API_URL,
  REACT_APP_API_URL,
  REACT_APP_AUTH_API_URL,
  REACT_APP_KEYCLOAK_URL,
  REACT_APP_KEYCLOAK_REALM,
  REACT_APP_KEYCLOAK_CLIENT_ID,
  REACT_APP_RECAPTCHA_SITE_KEY,
  REACT_APP_RTP_API_URL,
  REACT_APP_OIDC_USER_INFO_URL,
  REACT_APP_OIDC_USER_ISSUER,
  REACT_APP_GATEWAY_URL,
} = process.env;

const { protocol, hostname } = window.location;
export default {
  projectCommitDate: REACT_APP_PROJECT_COMMIT_DATE || '',
  projectCommitShortSHA: REACT_APP_PROJECT_COMMIT_SHORT_SHA || '',
  // boardApiGatewayUrl: `${protocol}//${hostname}/api/v1`,
  boardApiGatewayUrl: REACT_APP_GATEWAY_URL || `${protocol}//${hostname}/api/v1`,
  prmApiUrl: REACT_APP_API_URL || 'https://api.prm.sis.lt/v1',
  rtpApiUrl: REACT_APP_RTP_API_URL || 'https://api.rtp.sis.lt/v1',
  authApiUrl: REACT_APP_AUTH_API_URL || `${protocol}//api.${hostname}/auth/v1`,
  storageApiUrl: REACT_APP_STORAGE_API_URL || `${protocol}//api.${hostname}/storage/v1`,
  cryptoapiUrl: `${protocol}//crypto.sis.lt/api/v1`,
  recaptcha: {
    siteKey: REACT_APP_RECAPTCHA_SITE_KEY || '6Ld5J9UfAAAAAA65RNA9SDAVNnDjk7ulPly5E7De',
  },
  eIdEasy: {
    clientId: 'yBGm3UqZVWeLNlbv6kH7MIMI7WgWyrUW', // 'kWciHBseCA7nsBEg1vr7Teyr2NGsT4C8',
  },
  keycloak: {
    url: REACT_APP_KEYCLOAK_URL || 'https://sso.testinfra.sis.lt',
    realm: REACT_APP_KEYCLOAK_REALM || 'rtp',
    clientId: REACT_APP_KEYCLOAK_CLIENT_ID || 'prm-api',
  },
  oidc: {
    userInfoUrl: REACT_APP_OIDC_USER_INFO_URL || 'http://localhost:8081/v1/users/info',
    grantType: {
      authentication: 'password',
      refresh: 'refresh_token',
    },
    user: {
      aud: REACT_APP_KEYCLOAK_CLIENT_ID || 'rtp-api',
      // iss: REACT_APP_OIDC_USER_ISSUER || 'https://access.tst.gke.sis.lt/realms/rtp',
      iss: REACT_APP_OIDC_USER_ISSUER || 'https://id.sis.lt/realms/id',

    },
  },
};
