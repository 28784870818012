import React, { useState } from 'react';
import { Layout, Menu, Typography } from 'antd';
import { NavLink } from 'react-router-dom';

import './SideMenu.scss';

const { Sider } = Layout;

interface ISideMenuItem {
  name: string;
  route: string;
  icon: React.ReactNode;
}

type SideMenuProps = {
  chosenItem: string;
  items: ISideMenuItem[];
};

const SideMenu: React.FC<SideMenuProps> = ({ chosenItem, items }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Sider
      breakpoint="lg"
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      id="SideMenu"
    >
      <div className="logo">
        <NavLink to="/">
          {collapsed ? (
            <Typography.Title style={{ color: '#fff', textAlign: 'center' }} level={3} type="secondary">
              Ip
            </Typography.Title>
          ) : (
            <Typography.Title
              style={{
                color: '#fff',
                whiteSpace: 'nowrap',
                textAlign: 'center',
              }}
              level={3}
              type="secondary"
            >
              Ino-pay
            </Typography.Title>
          )}
        </NavLink>
      </div>
      <Menu theme="dark" mode="inline" selectedKeys={[chosenItem]}>
        {items.map((item) => (
          <Menu.Item key={item.route} icon={item.icon}>
            <NavLink to={item.route}>{item.name}</NavLink>
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  );
};
export default SideMenu;
