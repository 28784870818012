/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import {
  Form, Col, Row, Input, Select, Upload, Button, Checkbox, InputNumber, message, DatePicker,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { UploadOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

import './PaymentForm.scss';

import { IPrmDocument } from 'types/prm';
import { IGeneratePrmRequest } from 'types/requests';

// import userManager from 'services/userManager';
import userManager from 'utils/userManager';

const { Option } = Select;

export interface IPaymentFormProps {
  title: string;
  formData?: any;
  loading?: boolean;
  visible?: boolean;
  onClose: () => void;
  onSubmit: (data: IGeneratePrmRequest) => Promise<void>;
}

const PaymentForm = ({
  loading,
  onClose,
  onSubmit,
}: IPaymentFormProps) => {
  const { t }: { t: any } = useTranslation();
  const [form] = Form.useForm();
  const [paymentInvoice, setPaymentInvoice] = useState<IPrmDocument | undefined>();
  const [subId, setSubId] = useState<string>('');
  const [currency, setCurrency] = useState<string>('EUR');
  const [xpryDt, setXpryDt] = useState<string>();
  const [reqdExctnDt, setReqdExctnDt] = useState<string>();

  const MAX_NOTE_LENGTH = 200;

  const submitForm = async (data: any) => {
    await onSubmit({
      subId,
      currency,
      amount: data.amount,
      orderId: data.orderId,
      description: data.description,
      document: paymentInvoice,
      xpryDt,
      reqdExctnDt,
      payer: (data.payerEmail && data.payerName) ? { email: data.payerEmail, name: data.payerName } : undefined,
    });
    form.resetFields();
    setXpryDt(undefined);
    setReqdExctnDt(undefined);
    setPaymentInvoice(undefined);
  };

  const closeForm = () => {
    form.resetFields();
    onClose();
  };

  const file2Base64 = (file:File):Promise<string> => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || '');
    reader.onerror = (error) => reject(error);
  });

  const paymentInvoiceUpload = async (file: any) => {
    // ! deny files with dots in their names?
    const base64 = await file2Base64(file as File);
    const content = base64.replace(/^data:.*\/\w+;base64,/, '');
    const contentType = base64.match(/data:.*;/)![0].replace('data:', '').slice(0, -1);
    const documentChunks = file.name.split('.');
    const type = documentChunks.pop().toLowerCase();
    const name: string = documentChunks.join('.');
    if (!name.includes('.')) {
      setPaymentInvoice({
        name,
        type,
        content,
        contentType,
      });

      form.setFieldsValue({ name, type });
    } else {
      message.error('Document name can not contain dots (.)', 3);
    }
  };

  const setUserSubId = async () => {
    const currentUser = await userManager.getUser();
    if (currentUser) {
      const { sub } = currentUser.profile;
      setSubId(sub!);
      form.setFieldsValue({ subId: sub });
    }
  };

  useEffect(() => {
    setUserSubId();
  }, []);

  return (
    <div id="PaymentForm">
      <Form layout="vertical" onFinish={submitForm} form={form} hideRequiredMark>
        <Form.Item
          label={t('paymentsPage.paymentForm.paymentName')}
          name="orderId"
          className="no-help"
          rules={[{ required: true }]}
        >
          <Input
            disabled={loading}
            style={{ width: '100%' }}
            placeholder={t('paymentsPage.paymentForm.paymentNamePlaceholder')}
          />
        </Form.Item>
        <Row align="top" gutter={8}>
          <Col span={18}>
            <Form.Item
              name="amount"
              label={t('paymentsPage.paymentForm.amount')}
              initialValue="0.01"
              className="no-help"
              rules={[{ required: true }]}
            >
              <InputNumber
                min={0.01}
                disabled={loading}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="currency"
              label={t('paymentsPage.paymentForm.currency')}
            >
              <Select disabled defaultValue="EUR" onChange={(e) => setCurrency(e)}>
                <Option value="EUR">EUR</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row align="top" gutter={8}>
          <Col span={12}>
            <Form.Item
              name="xpryDt"
              label="Expiry Date"
              initialValue=""
              className="no-help"
            >
              <DatePicker style={{ width: '100%' }} onChange={(date, dateString) => setXpryDt(dateString)} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="reqdExctnDt"
              label="Required execution date"
              initialValue=""
              className="no-help"
            >
              <DatePicker style={{ width: '100%' }} onChange={(date, dateString) => setReqdExctnDt(dateString)} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="description"
          rules={[{ required: true }]}
          help={`${t('paymentsPage.paymentForm.message.notesLength')}`}
          label="Description"
        >
          <TextArea
            showCount
            disabled={loading}
            style={{ width: '100%' }}
            maxLength={MAX_NOTE_LENGTH}
          />
        </Form.Item>
        <Row align="top" gutter={8}>
          <Col span={18}>
            <Form.Item
              name="name"
              className="no-help"
              label={t('paymentsPage.paymentForm.title')}
              rules={[
                {
                  max: 64,
                  message: `${t('paymentsPage.paymentForm.message.fingerprintLength')}`,
                },
              ]}
            >
              <Input
                disabled={loading}
                style={{ width: '100%' }}
                placeholder={t('paymentsPage.paymentForm.titlePlaceholder')}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="type"
              label={t('paymentsPage.paymentForm.format')}
            >
              <Input style={{ width: '100%' }} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" gutter={8}>
          <Col span={24}>
            <Upload
              className="upload upload--small"
              multiple={false}
              showUploadList={false}
              beforeUpload={paymentInvoiceUpload}
            >
              {/* <Button className="button-round button-white"> */}
              <UploadOutlined className="upload-icon upload-icon--small" />
              <span>{t('paymentsPage.paymentForm.uploadText')}</span>
              {/* </Button> */}
            </Upload>
          </Col>
        </Row>
      </Form>
      <div className="footer">
        <Button
          disabled={loading}
          onClick={closeForm}
          className="button-round button-white"
        >
          {t('paymentsPage.paymentForm.cancelButton')}
        </Button>
        <Button
          loading={loading}
          onClick={() => {
            form.submit();
          }}
          type="primary"
          className="button-round button-green"
        >
          {t('paymentsPage.paymentForm.submitButton')}
        </Button>
      </div>
    </div>
  );
};

export default PaymentForm;
