import MethodSelection from '../views/MethodSelection.vue';
import SmartIdAuth from '../views/SmartIdAuth';
import MobileIdAuth from '../views/MobileIdAuth';
import IdentificationFrejaId from './IdentificationFrejaId';
import IdentificationZealId from './IdentificationZealId';
import SignatureSmartId from './SignatureSmartId';
import SignatureMobileId from './SignatureMobileId';
import SignatureFtn from './SignatureFtn';
import SignatureOtp from './SignatureOtp';
import SignatureEvrotrust from './SignatureEvrotrust';
import SignatureDTrustSignMe from './SignatureDTrustSignMe';

const views = Object.freeze({
  MethodSelection,
  SmartIdAuth,
  MobileIdAuth,
  IdentificationFrejaId,
  IdentificationZealId,
  SignatureSmartId,
  SignatureMobileId,
  SignatureFtn,
  SignatureOtp,
  SignatureEvrotrust,
  SignatureDTrustSignMe,
});

export default views;
