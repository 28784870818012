import {
  signUpUser,
  updateUser,
  resetPassword,
  updatePassword,
  verifyUserEmail,
  confirmUserWithEid,
  requestPasswordReset,
  validateUserActionToken,
  requestUserEmailVerification,
} from './users';

import {
  authenticateUser,
  updateAccessToken,
} from './authorization';

import notifyPayer from './mail';

import {
  instance,
  // setAuthAccessToken,
} from './instance';

export default {
  instance,
  notifyPayer,
  // setAuthAccessToken,
  signUpUser,
  resetPassword,
  updateUser,
  updatePassword,
  requestPasswordReset,
  validateUserActionToken,
  requestUserEmailVerification,
  verifyUserEmail,
  confirmUserWithEid,
  authenticateUser,
  updateAccessToken,
};
