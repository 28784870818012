/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import {
  Button, message, Typography, Spin,
} from 'antd';
import {
  ReloadOutlined, PlusOutlined, LoadingOutlined, ArrowRightOutlined,
} from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';

import { IBreadcrumbsAction, getBreadcrumbsAction } from 'modules/breadcrumbs/actions';
import { BreadcrumbsAction } from 'modules/breadcrumbs/types';

import { getPrmIdService, getPrmListService, getPrmQrCodeService } from 'services/prm';

import { IRouterProps } from 'types/router';
import { IGeneratePrmRequest } from 'types/requests';
import { IPrm } from 'types/prm';

import PaymentForm from 'views/forms/PaymentForm/PaymentForm';
import PaymentsTable from '../../components/PaymentsTable/PaymentsTable';
import SearchPanel from '../../components/SearchPanel/SearchPanel';

import './PaymentsPage.scss';

const { Title } = Typography;

interface IDocumentsPageProps {
  getBreadcrumbsActionDispatch: (path: string, lastBreadcrumb: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch<BreadcrumbsAction>) => ({
  getBreadcrumbsActionDispatch: (path: string, lastBreadcrumb: string) => {
    getBreadcrumbsAction(path, lastBreadcrumb)(dispatch);
  },
});

type Props =
  IDocumentsPageProps
  & IRouterProps
  & IBreadcrumbsAction;

const PaymentsPage = (props: Props) => {
  const history = useHistory();
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [bookmark, setBookmark] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(0);
  const [payments, setPayments] = useState<IPrm[]>([]);
  const [creatingPayment, setCreatingPayment] = useState<boolean>(false);
  const [isCreatePaymentModalOpen, setIsCreatePaymentModalOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  const fetchPayments = async () => {
    setLoading(true);

    try {
      const paymentsResponse = await getPrmListService();
      setPayments(paymentsResponse);
    } catch (err) {
      message.error(`${err}`, 3);
    } finally {
      setLoading(false);
    }
  };

  const filterDocuments = async (search: string) => {
    try {
      setLoading(true);
      setPayments([]);
      const paymentsResponse = await getPrmListService(search);
      setPayments(paymentsResponse);
    } catch (err: any) {
      message.error(err.message, 3);
    } finally {
      setLoading(false);
    }
  };

  const onPaymentFormSubmit = async (data: IGeneratePrmRequest) => {
    setCreatingPayment(true);
    try {
      await getPrmIdService(data);
      fetchPayments();
    } catch (err) {
      console.log(err);
      message.error('Some error occurred, try again later');
    } finally {
      setCreatingPayment(false);
      setIsCreatePaymentModalOpen(false);
    }
  };

  useEffect(() => {
    const { location, getBreadcrumbsActionDispatch } = props;
    getBreadcrumbsActionDispatch(location.pathname, '');

    fetchPayments();
  }, []);

  return (
    <div id="PaymentsPage">
      <Title level={4} className="title">
        {t('paymentsPage.searchPanel.title')}
      </Title>
      <SearchPanel
        filterPRMs={(search) => filterDocuments(search)}
      />
      <div>
        <div className="payments-header">
          <Title level={4} className="title">
            {t('paymentsPage.table.title')}
          </Title>
          <div className="payments-header-buttons">
            <Button
              className="button-round button-green"
              onClick={() => fetchPayments()}
              disabled={loading}
              icon={loading ? <LoadingOutlined /> : <ReloadOutlined />}
            >
              {t('paymentsPage.buttons.refresh')}
            </Button>
            <Button
              className="button-round button-green"
              onClick={() => { history.push('/rtp/receive'); }}
              icon={<ArrowRightOutlined />}
            >
              {t('paymentsPage.buttons.receive')}
            </Button>
            <Button
              onClick={() => setIsCreatePaymentModalOpen(true)}
              type="primary"
              icon={<PlusOutlined />}
              className="button-round button-green"
            >
              {t('paymentsPage.buttons.new')}
            </Button>
          </div>
        </div>
        {payments && (
        <>
          <PaymentsTable
            loading={loading}
            items={payments}
          />
          {/* <div className="button-center">
            <Button
              className="button-round"
              onClick={() => fetchPayments()}
              disabled={loading || count !== pageSize}
            >
              {t('paymentsPage.table.loadMoreButton')}
              {loading ? <LoadingOutlined /> : <ReloadOutlined />}
            </Button>
          </div> */}
        </>
        )}
      </div>
      <Modal
        title={t('paymentsPage.paymentForm.formTitle')}
        visible={isCreatePaymentModalOpen}
        footer={false}
        onCancel={() => {
          if (!creatingPayment) setIsCreatePaymentModalOpen(false);
        }}
      >
        {/* {creatingPayment && (
        <div className="documentUploadSpinner">
          <Spin />
        </div>
        )} */}
        <PaymentForm
          loading={creatingPayment}
          onSubmit={(data: IGeneratePrmRequest) => onPaymentFormSubmit(data)}
          onClose={() => setIsCreatePaymentModalOpen(false)}
          title="Test"
        />
      </Modal>
    </div>
  );
};
export default connect(null, mapDispatchToProps)(PaymentsPage);
