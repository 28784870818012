import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Avatar from 'antd/lib/avatar/avatar';
import { Button, Layout } from 'antd';

import Flags from 'country-flag-icons/react/3x2';

// import userManager from 'services/userManager';
import userManager from 'utils/userManager';

import { useTranslation } from 'react-i18next';
import { changeLanguage } from '../../../i18n/i18n';

import './Header.scss';

const { Header } = Layout;

// const SideMenuItems = [
//     {
//         name: "Documents",
//         route: "/board/documents",
//         icon: <FileSearchOutlined />
//     },
//     {
//         name: "Signatures",
//         route: "/board/signatures",
//         icon: <HighlightOutlined />
//     },
//     {
//         name: "Transactions",
//         route: "/board/transactions",
//         icon: <ApartmentOutlined />
//     }
// ];

const logout = async () => {
  // window.location.replace('/login');
  // userManager.removeUser();
  const user = await userManager.getUser();
  userManager.signoutRedirect({ id_token_hint: user?.id_token });
  userManager.removeUser();
};

const CustomHeader: React.FC = (props: any) => {
  // const { userManager: { user } } = props;
  const { oidc: { user } } = props;
  const { family_name: familyName, given_name: givenName, name } = user?.profile || {};

  const onHomePage = window.location.pathname === '/';

  const { t }: { t: any } = useTranslation();

  return (
    <div id="CustomHeader">
      <Header className="site-layout-background header">
        <div className="login-header">
          <Flags.RU title="RU" className="flag-icon" id="ru" onClick={() => changeLanguage('ru')} />
          <Flags.LT title="LT" className="flag-icon" id="lt" onClick={() => changeLanguage('lt')} />
          <Flags.GB title="EN" className="flag-icon" id="en" onClick={() => changeLanguage('en')} />
        </div>
        <div className="login-header login-header-buttons sm-flex-column">
          {user ? (
            <>
              <NavLink className="userAvatar" to="/board/profile">
                <div>
                  <Avatar>{`${givenName ? givenName[0] : ''}${familyName ? familyName[0] : ''}`}</Avatar>
                </div>
                <div className="text-grey">{name}</div>
              </NavLink>
              {onHomePage && (
              <NavLink to="/board/payments">
                <Button className="button-colored">{t('header.dashboardButton')}</Button>
              </NavLink>
              )}
              <Button className="button-colored" onClick={logout} type="primary">
                {t('header.logoutButton')}
              </Button>
            </>
          ) : (
            <NavLink to="/login">
              <Button className="button-colored" type="primary">
                {t('header.loginButton')}
                {' '}
                &#62;
              </Button>
            </NavLink>
          )}
        </div>
      </Header>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  ...state,
});

export default connect(mapStateToProps)(CustomHeader);
