import React from 'react';
import './Breadcrumb.scss';
import { Breadcrumb } from 'antd';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from 'modules';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface ICustomBreadcrumbProps {
  breadcrumbs: any;
}

const mapStateToProps = (state: ApplicationState) => ({
  breadcrumbs: state.breadcrumbs.breadcrumbs,
});

type Props = RouteComponentProps & ICustomBreadcrumbProps;

const CustomBreadcrumb = withRouter((props: Props) => {
  const { breadcrumbs } = props;
  const [t] = useTranslation();

  const extraBreadcrumbItems = Object.keys(breadcrumbs).map((url) => {
    const breadcrumbValue = (() => {
      if (breadcrumbs[url] === 'profile') {
        return t('breadcrumbs.profile');
      }
      if (breadcrumbs[url] === 'payments') {
        return t('breadcrumbs.payments');
      }
      return breadcrumbs[url];
    })();

    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbValue}</Link>
      </Breadcrumb.Item>
    );
  }).filter(Boolean);

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">{t('breadcrumbs.home')}</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <div className="site-layout-background Breadcrumb">
      <Breadcrumb>{breadcrumbItems}</Breadcrumb>
    </div>
  );
});

export default connect(mapStateToProps)(CustomBreadcrumb);
