/* eslint-disable */ 
import { Form, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React from 'react';
import { RtpConfig} from 'types/requests';
import CollapsableSection from '../CollapsableSection/CollapsableSection';
import styles from './RtpSettings.module.scss';

interface Props {
  rtpConfig?: RtpConfig
}

const RtpSettings = ({ rtpConfig }: Props) => (
  <CollapsableSection
    title="Rtp Configuration"
    className={styles.rtpConfig}
  >
    <FormItem
      label="ID"
      name="rtpId"
    >
      <Input placeholder="Type your clientID"/>
    </FormItem>

    <FormItem
      label="Name"
      name="rtpName"
    >
      <Input placeholder="Type your name"/>  
    </FormItem> 

    <FormItem
      label="Type"
      name="rtpType"
    >
      <Input placeholder="Type your type" />
    </FormItem>

    <FormItem
      label="Bank IBAN"
      name="rtpIban"
    >
      <Input placeholder="Type your Bank IBAN" />
    </FormItem>

    <FormItem
      label="Bank SWIFT"
      name="rtpSwift"
    >
      <Input placeholder="Type your Bank Swift" />
    </FormItem>
    <button type='submit'>Update</button>
  </CollapsableSection>
);

export default RtpSettings;
