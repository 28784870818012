import axios from 'axios';
import config from '../../config';

export const instance = axios.create({
  baseURL: config.authApiUrl,
  // baseURL: 'https://api.prm.sis.lt/v1',
});

// export const setAuthAccessToken = (token?: string) => {
//   if (token) {
//     instance.defaults.headers.common.Authorization = `Bearer ${token}`;
//   } else {
//     delete instance.defaults.headers.common.Authorization;
//   }
// };

export const setAuthAcceptLanguageHeader = (language: string) => {
  instance.defaults.headers.common['Accept-Language'] = language;
};
