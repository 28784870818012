import Vue from "vue";
import {methodTypes} from './config';
import callingCodes from './callingCodes';
import viewNames from './views/viewNames';
import idCardTranslationsKeys from './idCardTranslationsKeys';

const createStore = function createStore({methodsConfig}) {
  const {availableMethods, methodsByActionType} = methodsConfig;
  const store = Vue.observable({
    countryCode: 'EE',
    currentView: viewNames.MethodSelection,
    currentMethod: null,
    currentViewTitleTranslationKey: '',
    isLoading: false,
    flashMessages: [],
    signingMethods: availableMethods[methodTypes.SIGNATURE],
    identificationMethods: availableMethods[methodTypes.IDENTIFICATION],
    enabledCountries: 'all',
    inputValues: {},
    otpEmail: null,
    otpPhone: null,
    additionalMethodConfig: {},
    signatureIsProcessing: false,
  });

  const filterMethodsByCountry = function filterMethodsByCountry(methods, country) {
    return methods.filter(method => {
      return method.supportedCountries.includes(country) || method.supportedCountries.includes('WORLD');
    });
  }

  const getters = {
    countryCode: () => {
      const availableCountries = getters.availableCountries();
      let currentCountryCode = store.countryCode;
      if (!availableCountries.includes(currentCountryCode)) {
        currentCountryCode = availableCountries[0];
      }
      return currentCountryCode;
    },
    currentView: () => store.currentView,
    currentMethod: () => store.currentMethod,
    currentViewTitleKey: () => {
      const currentMethod = store.currentMethod && store.currentMethod.actionType;
      return methodsByActionType[currentMethod].translationKeys && methodsByActionType[currentMethod].translationKeys.title;
    },
    isLoading: () => store.isLoading,
    flashMessages: () => store.flashMessages,
    identificationMethods: () => store.identificationMethods,
    identificationMethodsInCurrentCountry: () => {
      return filterMethodsByCountry(store.identificationMethods, store.countryCode);
    },
    signingMethods: () => store.signingMethods,
    signingMethodsInCurrentCountry: () => {
      return filterMethodsByCountry(store.signingMethods, store.countryCode);
    },
    availableCountries: () => {
      const allMethods = [
        ...store.signingMethods,
        ...store.identificationMethods,
      ];
      let {enabledCountries} = store;
      let resultingCountries = allMethods.map(method => method.supportedCountries).flat();
      if (enabledCountries !== 'all' && Array.isArray(enabledCountries)) {
        enabledCountries = enabledCountries.map(countryCode => countryCode.toUpperCase());
        resultingCountries = resultingCountries.filter(countryCode => enabledCountries.includes(countryCode));
      }
      // we use Set to remove duplicates
      return [...new Set(resultingCountries)].map(countryCode => countryCode.toUpperCase());
    },
    callingCode: () => {
      return callingCodes[store.countryCode];
    },
    inputValues: () => store.inputValues,
    otpEmail: () => store.otpEmail,
    otpPhone: () => store.otpPhone,
    emailToken: () => store.emailToken,
    additionalMethodConfig: () => store.additionalMethodConfig,
    signatureIsProcessing: () => store.signatureIsProcessing,
    userActionsEnabled: () => {
      return !store.signatureIsProcessing && !store.isLoading;
    },
  }

  const mutations = {
    setCountryCode(countryCode) {
      store.countryCode = countryCode;
    },
    setCurrentView(template) {
      store.currentView = template;
    },
    setCurrentMethod(methodObj) {
      store.currentMethod = methodObj;
    },
    setIsLoading(value) {
      store.isLoading = value;
    },
    addFlashMessage(message) {
      store.flashMessages.push(message);
    },
    clearFlashMessages() {
      store.flashMessages = [];
    },
    setIdentificationMethods(methods) {
      store.identificationMethods = methods;
    },
    setSigningMethods(methods) {
      store.signingMethods = methods;
    },
    setEnabledCountries(countries) {
      store.enabledCountries = countries;
    },
    setInputValues(values) {
      store.inputValues = values;
    },
    setOtpEmail(value) {
      store.otpEmail = value;
    },
    setOtpPhone(value) {
      store.otpPhone = value;
    },
    setEmailToken(value) {
      store.emailToken = value;
    },
    setAdditionalMethodConfig(value) {
      store.additionalMethodConfig = value;
    },
    setSignatureIsProcessing(value) {
      store.signatureIsProcessing = value;
    },
  };

  const actions = {
    loadingStart() {
      mutations.setIsLoading(true);
    },
    loadingEnd() {
      mutations.setIsLoading(false);
    },
    changeCountry(newCountryCode) {
      let countryCode = newCountryCode.toUpperCase();
      const availableCountries = getters.availableCountries();
      if (availableCountries.indexOf(countryCode) !== -1) {
        mutations.setCountryCode(countryCode);
      } else {
        mutations.setCountryCode(availableCountries[0]);
      }
    },
    selectMethod(actionType) {
      const timestamp = Date.now();
      const {viewName} = methodsByActionType[actionType];
      mutations.clearFlashMessages();
      mutations.setCurrentMethod({
        actionType,
        timestamp,
      });
      if (viewName) {
        actions.changeView(viewName);
      }
    },
    changeView(viewName) {
      mutations.clearFlashMessages();
      mutations.setCurrentView(viewName);
    },
    addFlashMessage(data) {
      const message = {
        text: '',
        scheme: 'info',
      };

      if (data.details && data.details.message === 'no_implementation') {
        message.scheme = 'danger';
        const translationKeys = idCardTranslationsKeys[store.countryCode] || {};
        message.translationKey = translationKeys.no_implementation;
      } else if ((data.userMessage || data.message) && data instanceof Error) {
        message.scheme = 'danger';
        message.text = data.userMessage || data.message;
      } else if (data.details && data.details.isCancel) {
        message.scheme = 'info';
        message.translationKey = 'USER_REFUSED';
      } else {
        message.scheme = 'danger';
        message.translationKey = 'no-response-error';
      }

      if (data.response) {
        message.response = data.response;
      }

      mutations.addFlashMessage(message);
    },
    clearFlashMessages() {
      mutations.clearFlashMessages();
    },
    updateEnabledCountries(countries) {
      mutations.setEnabledCountries(countries);
    },
    changeMethods(enabledMethods) {
      const calculateEnabledMethods = function calculateEnabledMethods(availableMethods, enabledMethods) {
        let result = [];

        if (!enabledMethods) {
          return result;
        }

        if (enabledMethods === 'all' || enabledMethods === true) {
          result = availableMethods;
        } else if (Array.isArray(enabledMethods)) {
          enabledMethods.forEach(enabledActionType => {
            const method = availableMethods.find(method => method.actionType === enabledActionType);

            if (method) {
              result.push(method);
              mutations.setIdentificationMethods(identificationMethods);
            }
          });
        }
        return result;
      };

      let identificationMethods = calculateEnabledMethods(availableMethods[methodTypes.IDENTIFICATION], enabledMethods[methodTypes.IDENTIFICATION]);
      let signingMethods = calculateEnabledMethods(availableMethods[methodTypes.SIGNATURE], enabledMethods[methodTypes.SIGNATURE]);

      if (identificationMethods) {
        mutations.setIdentificationMethods(identificationMethods);
      }

      if (signingMethods) {
        mutations.setSigningMethods(signingMethods);
      }
    },
    updateInputValues(newValues) {
      const oldValues = getters.inputValues();

      mutations.setInputValues({
        ...oldValues,
        ...newValues,
      });
    },
    updateOtpEmail(newValue) {
      mutations.setOtpEmail(newValue);
    },
    updateOtpPhone(newValue) {
      mutations.setOtpPhone(newValue);
    },
    updateEmailToken(newValue) {
      mutations.setEmailToken(newValue);
    },
    changeAdditionalMethodConfig(newValue) {
      mutations.setAdditionalMethodConfig(newValue);
    },
    startSignatureProcessing() {
      mutations.setSignatureIsProcessing(true);
    },
    endSignatureProcessing() {
      mutations.setSignatureIsProcessing(false);
    },
  }

  return {
    store,
    actions,
    getters,
  }
}

export default createStore;
