import { AxiosResponse } from 'axios';
import { UserRepresentation } from 'types/user';
import { instance } from './instance';

export const signUpUser = (
  user: UserRepresentation,
  recaptcha: string,
): Promise<AxiosResponse<any>> => instance
  .post<any>('/users', user, { params: { recaptcha } });

export const updateUser = (
  currentEmail : string,
  userData: UserRepresentation,
  recaptcha: string,
): Promise<AxiosResponse<any>> => instance
  .put<any>('/users', { currentEmail, userData }, { params: { recaptcha } });

export const confirmUserWithEid = (
  currentEmail : string,
  userData: UserRepresentation,
  recaptcha: string,
): Promise<AxiosResponse<any>> => instance
  .put<any>('/users/confirm', { currentEmail, userData }, { params: { recaptcha } });

export const requestPasswordReset = (
  email: string,
  recaptcha: string,
): Promise<AxiosResponse<any>> => instance
  .put<any>(
  '/users/forgot-password',
  { email },
  { params: { recaptcha } },
);

export const validateUserActionToken = (
  token: string,
): Promise<AxiosResponse<boolean>> => instance
  .get<boolean>('/users/validate-user-action-token', {
  params: {
    token,
  },
});

export const resetPassword = (
  token: string,
  password: string,
  recaptcha: string,
): Promise<AxiosResponse<boolean>> => instance
  .put<boolean>(
  '/users/reset-password',
  { token, password },
  { params: { recaptcha } },
);

export const updatePassword = (
  email: string,
  password: string,
  newPassword: string,
  recaptcha: string,
): Promise<AxiosResponse<boolean>> => instance
  .put<boolean>(
  '/users/update-password',
  { email, password, newPassword },
  { params: { recaptcha } },
);

export const verifyUserEmail = (
  token: string,
): Promise<AxiosResponse<any>> => instance
  .put<any>(
  '/users/verify-email',
  { token },
);

export const requestUserEmailVerification = (
  email: string,
  recaptcha: string,
): Promise<AxiosResponse<any>> => instance
  .put<any>(
  '/users/requst-email-verification',
  { email },
  { params: { recaptcha } },
);
