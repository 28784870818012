/* eslint-disable */ 
import { Button, Divider } from 'antd';
import Form, { useForm } from 'antd/lib/form/Form';
import React, { FormEvent, useEffect } from 'react';
import { Account } from 'types/requests';
import useGetAccount from './hooks';
import PaymentSettings from './PaymentSettings.tsx/PaymentSettings';
import RtpSettings from './RtpSettings/RtpSettings';

interface ProfileForm {
  kevinClientId: string
  kevinClientSecret: string
  kevinIban: string
  kevinName: string
  kevinRedirectUrl: string
  montonioClientKey: string
  montonioClientSecret: string
  montonioName: string
  montonioRedirectUrl: string
  rtpId: string
  rtpType: string
  rtpName: string
  rtpIban: string
  rtpSwift: string
}

const ProfileSettings = () => {
  const [account, updateAcc] = useGetAccount()
  const [form] = useForm<ProfileForm>()
  
  useEffect(() => {
    account && form.setFieldsValue({
      rtpId: account.rtpConfig.party.id,
      rtpName: account.rtpConfig.party.name,
      rtpType: account.rtpConfig.party.type,
      rtpIban: account.rtpConfig.bank.iban,
      rtpSwift: account.rtpConfig.bank.swift,
      kevinClientId: account.kevinConfig.clientId,
      kevinClientSecret: account.kevinConfig.clientSecret,
      kevinIban: account.kevinConfig.iban,
      kevinName: account.kevinConfig.name,
      kevinRedirectUrl: account.kevinConfig.redirectUrl,
      montonioClientKey: account.montonioConfig.clientKey,
      montonioClientSecret: account.montonioConfig.clientSecret,
      montonioName: account.montonioConfig.name,
      montonioRedirectUrl: account.montonioConfig.redirectUrl
    })
  }, [account])

  const onFinish = (val: ProfileForm) => {
    const newAcc: Account = {
      kevinConfig: {
        clientId: val.kevinClientId,
        clientSecret: val.kevinClientSecret,
        iban: val.kevinIban,
        name: val.kevinName,
        redirectUrl: val.kevinRedirectUrl,
      },
      montonioConfig: {
        clientKey: val.montonioClientKey,
        clientSecret: val.montonioClientSecret,
        name: val.montonioName,
        redirectUrl: val.montonioRedirectUrl,
      },
      rtpConfig: {
        party: {
          id: val.rtpId,
          name: val.rtpName,
          type: val.rtpType,
        },
        bank: {
          iban: val.rtpIban,
          swift: val.rtpSwift
        }
      }
    };
    updateAcc(newAcc);
    alert('Updated')
  }

  return (
    <Form onFinish={onFinish} form={form}>
      <PaymentSettings/>
      <Divider />
      <RtpSettings />
    </Form>
  );
}

export default ProfileSettings;