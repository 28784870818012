import React, { useState } from 'react';

import { Button, Tooltip } from 'antd';
import { CopyTwoTone } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import './CopyButton.scss';

interface CopyButtonProps {
  tooltipText?: string
  disabled?: boolean
  copyText: string
  title: string
}

const TOOLTIP_TIMEOUT_MS = 1500;

const CopyButton = (props: CopyButtonProps) => {
  const {
    copyText, disabled, title, tooltipText,
  } = props;

  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <CopyToClipboard text={copyText}>
      <Tooltip
        placement="top"
        title={tooltipText}
        visible={showTooltip}
      >
        <Button
          disabled={disabled}
          icon={<CopyTwoTone />}
          onClick={() => {
            setShowTooltip(true);
            setTimeout(() => {
              setShowTooltip(false);
            }, TOOLTIP_TIMEOUT_MS);
          }}
        >
          {title}
        </Button>
      </Tooltip>
    </CopyToClipboard>
  );
};

CopyButton.defaultProps = {
  tooltipText: 'Successfuly copied',
  disabled: false,
};

export default CopyButton;
