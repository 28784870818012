import React, { ReactNode } from 'react';

import './BlockHeader.scss';

interface BlockHeaderProps{
  title: string
  children?: ReactNode
}
const BlockHeader = ({ title, children }: BlockHeaderProps) => (
  <div className="block-header">
    <h1 className="block-header-title">{title}</h1>
    <div>
      {children}
    </div>
  </div>
);

BlockHeader.defaultProps = {
  children: '',
};

export default BlockHeader;
