import React from 'react';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { push } from 'connected-react-router';
import { User } from 'oidc-client';
import { Dispatch } from 'redux';
import prmApi from 'apis/prm';
import rtpApi from 'apis/rtp';
import apiGateway from 'apis/api-gateway';
import userManager from '../../utils/userManager';

const CallbackPage = ({ dispatch }: { dispatch: Dispatch<any> }) => (
  <CallbackComponent
    userManager={userManager}
    successCallback={async (user: User) => {
      userManager.getUser();
      prmApi.setAccessToken(user.access_token);
      rtpApi.setAccessToken(user.access_token);
      apiGateway.setAccessToken(user.access_token);

      const redirectUrl = localStorage.getItem('login_redirect');
      localStorage.removeItem('login_redirect');
      await dispatch(push(redirectUrl || '/board/payments'));
    }}
    errorCallback={(error) => {
      prmApi.setAccessToken();
      rtpApi.setAccessToken();
      apiGateway.setAccessToken();
      dispatch(push('/'));
      console.log('error at the callback page');
      console.error(error);
    }}
  >
    <div>Redirecting...</div>
  </CallbackComponent>
);
export default connect()(CallbackPage);
