import { getAccount, putAccount } from 'apis/api-gateway';
import { useEffect, useState } from 'react';
import { Account } from 'types/requests';

type HookType = () => [Account | undefined, (acc: Account) => void];

const useGetAccount:HookType = () => {
  const [account, setAccount] = useState<Account>();

  useEffect(() => {
    const fetchAccount = async () => {
      const acc = await getAccount();
      setAccount(acc.data);
    };

    fetchAccount();
  }, []);

  const updateAccout = async (acc: Account) => {
    await putAccount(acc);
    setAccount(acc);
  };

  return [account, updateAccout];
};

export default useGetAccount;
