import React from 'react';
import Title from 'antd/lib/typography/Title';
import Dragger from 'antd/lib/upload/Dragger';
import { CloudUploadOutlined } from '@ant-design/icons';

import './LandingPage.scss';
import { useTranslation } from 'react-i18next';

const LandingPage = () => {
  const { t }: { t: any } = useTranslation();

  return (
    <div id="LandingPage">
      <div className="verify-document-container">
        <Title className="title" level={2}>
          {t('landingPage.title')}
        </Title>
        <p>
          {t('landingPage.textPart1')}
          {' '}
          <span className="text-green">
            {' '}
            {t('landingPage.textSpan')}
          </span>

          {t('landingPage.textPart2')}
        </p>
        <Dragger
          name="file"
          multiple={false}
          showUploadList={false}
          // beforeUpload={async (file: any) => {
          //   const hash: string = await new Promise((resolve) => {
          //     const reader = new FileReader();
          //     reader.readAsArrayBuffer(file);
          //     reader.onload = () => {
          //       resolve(sha256(arrayBufferToWordArray(reader.result)).toString());
          //     };
          //   });
          //   await getDocumentsByHash(hash);
          // }}
        >
          <p className="ant-upload-drag-icon">
            <CloudUploadOutlined />
          </p>
          <p className="ant-upload-text">{t('landingPage.draggerText')}</p>
        </Dragger>
      </div>
    </div>
  );
};

export default LandingPage;
