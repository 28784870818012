/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Input, Button, Divider, message } from 'antd';

import { useQuery } from 'utils';
import { initiatePrmService } from 'services/prm';

import './RtpConfirmationPage.scss';

const RtpConfirmationPage = () => {
  const queryParams = useQuery();
  const { t }: { t: any } = useTranslation();
  const [prmIdParam, setPrmIdParam] = useState<string>('');
  const [prmIdValue, setPrmIdValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>();

  const initiatePrm = async (prmId: string, gateway: 'KEVIN' | 'MONTONIO' | 'DEFAULT') => {
    setLoading(true);
    message.loading({
      content: 'loading...',
      key: 123,
    });
    try {
      const prmInitResponse = await initiatePrmService({
        prmId,
        gateway,
        // kevinRedirectUrl: `https://status.gw.ino-pay.com/?prmid=${prmId}`,
      });
      window.location.href = prmInitResponse.gatewayLink;
    } catch (error: any) {
      message.error(
        error.response.data.message ||
          (typeof error.response.data.data === 'string' ? error.response.data.data : null) ||
          error.response.data.error.description ||
          error.message,
        1.5,
      );
      console.log(error.response.data);
      setPrmIdParam('');
    } finally {
      setLoading(false);
      message.destroy(123);
    }
  };

  useEffect(() => {
    const prmId = (queryParams.get('prmid') || '').replace('*', '');
    setPrmIdParam(prmId);
    if (prmId) initiatePrm(prmId, 'DEFAULT');
  }, []);

  const prmIdForm = (
    <div className="main-email">
      <Form className="card card-email" layout="vertical" hideRequiredMark>
        <p className="caption caption__light-grey caption-500 card-heading">
          {/* {t('signupPage.confirmEmailPage.mainHeader')} */}
          Initiate or check payment
        </p>
        <Divider className="divider" />
        <div className="card-body">
          <Form.Item
            // name="email1"
            label="Enter prm Id"
            className="with-help"
            help={<span>For example: 13033f35579340278dba8b4ae0bb90c0</span>}
          >
            <Input
              disabled={loading}
              onChange={(e) => {
                setPrmIdValue(e.target.value);
              }}
            />
          </Form.Item>
        </div>
      </Form>
      <Button
        type="primary"
        disabled={loading}
        className="button-round button-round__primary"
        onClick={() => initiatePrm(prmIdValue, 'KEVIN')}
      >
        Initiate payment with Kevin
      </Button>
      <Button
        type="primary"
        disabled={loading}
        className="button-round button-round__primary"
        onClick={() => initiatePrm(prmIdValue, 'MONTONIO')}
      >
        Initiate payment with Montonio
      </Button>
    </div>
  );

  return <div id="RtpConfirmationPage">{prmIdParam ? 'loading' : prmIdForm}</div>;
};

export default RtpConfirmationPage;
