import React, {
  ReactNode,
  // useEffect,
  useLayoutEffect,
  useRef, useState,
} from 'react';
import { NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import {
  Badge,
  Tooltip,
  Skeleton,
} from 'antd';

import './Set.scss';

export interface ISetProps {
  label: string;
  value: string | undefined | null | Date;
  loading?: boolean;
  type?: string;
  link?: string;
}

type Status = 'success' | 'error' | 'default';

const Set: React.FC<ISetProps> = (props: ISetProps) => {
  let title: string;
  let badgeStatus: Status;
  const { t }: { t: any } = useTranslation();
  const divRef = useRef<HTMLDivElement>(null);
  const [isOverflown, setIsOverflown] = useState<boolean>(false);

  const {
    value, label, loading, link, type,
  } = props;

  switch (value) {
    case 'accept':
      title = `${t('status.accepted')}`;
      badgeStatus = 'success';
      break;
    case 'reject':
      title = `${t('status.rejected')}`;
      badgeStatus = 'error';
      break;
    default:
      title = 'Created';
      badgeStatus = 'default';
      break;
  }

  const addTooltip = (child: ReactNode) => (
    <Tooltip
      placement="topLeft"
      title={value}
    >
      {child}
    </Tooltip>
  );

  const statusContent = (
    <div>
      <Badge status={badgeStatus} />
      {title}
    </div>
  );

  const textContent = (
    <div className="value" ref={divRef}>
      {
        type === 'link'
          ? <NavLink to={link || ''}>{value || '-'}</NavLink>
          : value || '-'
      }
    </div>
  );

  const skeletonPlaceholder = (
    <Skeleton
      className="status-skeleton"
      paragraph={false}
      title={{ width: '25%' }}
      active
    />
  );

  useLayoutEffect(() => {
    setIsOverflown(divRef.current ? divRef.current.scrollWidth > divRef.current.offsetWidth : false);
  }, []);

  const contentByType = type === 'status' ? statusContent : textContent;

  const content = isOverflown ? addTooltip(contentByType) : contentByType;

  return (
    <div className="Set">
      <div className="label">
        {label}
      </div>
      {loading ? skeletonPlaceholder : content}
    </div>
  );
};
export default Set;
