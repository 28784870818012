import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { setAcceptLanguageHeader } from 'apis/api-gateway/instance';
import lt from './lt.json';
import en from './en.json';
import ru from './ru.json';
import { setAuthAcceptLanguageHeader } from '../apis/auth/instance';

export const defaultNS = 'common';
export const resources = {
  lt,
  en,
  ru,
};

const availableLanguages = Object.keys(resources);

i18n.use(initReactI18next).use(LanguageDetector).init({
  resources,
  defaultNS,
  fallbackLng: 'en',
});

export const changeLanguage = (language: string) => {
  i18n.changeLanguage(language);
  setAcceptLanguageHeader(language);
  setAuthAcceptLanguageHeader(language);
};

setAuthAcceptLanguageHeader(i18n.resolvedLanguage);

export default availableLanguages;
