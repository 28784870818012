import CryptoJS from 'crypto-js';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

interface ITransformDateConfig {
  showYear: boolean
  showSeconds: boolean
}

export const transformDate = (
  date?: string,
  config: ITransformDateConfig = { showYear: true, showSeconds: false },
) => (`
    ${(new Date(date || '').toLocaleDateString('lt-lt')).slice(config.showYear ? 0 : 5)}
    ${new Date(date || '').toLocaleTimeString('lt-lt', {
    hour: '2-digit',
    minute: '2-digit',
    second: config.showSeconds ? '2-digit' : undefined,
  })}`);

export const arrayBufferToWordArray = (ab: any) => {
  const i8a = new Uint8Array(ab);
  const a = [];
  for (let i = 0; i < i8a.length; i += 4) {
    a.push(
      (i8a[i] << 24) | (i8a[i + 1] << 16) | (i8a[i + 2] << 8) | i8a[i + 3],
    );
  }
  return CryptoJS.lib.WordArray.create(a, i8a.length);
};

export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const downloadBlobFile = (fileData: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(fileData);
  const a = document.createElement('a');
  a.href = url;
  a.setAttribute('download', fileName);
  a.click();
};

type MapperType = {
  [key: string]: string
};

export const MIMEtoFormatMapper: MapperType = {
  'text/xml': 'xml',
  'application/xml': 'xml',
  'application/pdf': 'pdf',
  'application/json': 'json',
};

export const statusToColorMapper: MapperType = {
  unknown: 'grey',
  created: 'blue',
  received: 'yellow',
  accepted: 'orange',
  rejected: 'red',
  payed: 'green',
  started: 'pink',
};
