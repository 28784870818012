import { ActionTypes, UserManagerAction, UserManagerState } from './types';

const initialState: UserManagerState = {};

const userManager = (state = initialState, action: UserManagerAction): UserManagerState => {
  switch (action.type) {
    case ActionTypes.STORE_USER:
      return {
        ...state,
        user: action.user,
      };
    case ActionTypes.REMOVE_USER:
      return {
        ...state,
        user: undefined,
      };
    default:
      return state;
  }
};

export default userManager;
