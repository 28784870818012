import React, { useEffect } from 'react';
// import { useTranslation } from 'react-i18next';

// import { message } from 'antd';

import '@eid-easy/eideasy-widget';

import config from 'config';

type EidEasyWidgetProps = {
  onSuccess: (data: any) => void,
};
// ! check if there's already a user with this national id code
const EidEasyWidget = ({
  onSuccess,
}: EidEasyWidgetProps) => {
  const initWidget = () => {
    const widgetHolder = document.getElementById('eid-profile-confirm');
    const eidEasyWidget: any = document.createElement('eideasy-widget');

    const settings: any = {
      clientId: config.eIdEasy.clientId, // Required
      countryCode: 'LT', // Required, ISO 3166  two letter country code
      redirectUri: window.location.origin, // Required
      apiEndpoints: {
        identityStart: () => `${config.boardApiGatewayUrl}/eideasy/identity/start`, // Required
        identityFinish: () => `${config.boardApiGatewayUrl}/eideasy/identity/finish`, // Requried
      },
      language: 'en',
      enabledMethods: {
        identification: [
          // 'ee-id-login',
          // 'lv-id-login',
          // 'be-id-login',
          // 'fi-id-login',
          // 'lt-id-login',
          // 'pt-id-login',
          // 'rs-id-login',
          'lt-mobile-id',
          'mid-login',
          'smartid',
        ],
      },
      // ! check on backend if user is already verified
      async onSuccess({ data }: { data: any }) {
        onSuccess(data);
      },
      onFail(err: any) {
        alert(`
          ${err.response.data.status}
          ${err.response.data.message}
          `);
      },
    };

    Object.keys(settings).forEach((key) => {
      eidEasyWidget[key] = settings[key];
    });

    widgetHolder!.appendChild(eidEasyWidget);
  };

  useEffect(() => {
    initWidget();
  }, []);

  return (
    <div id="eid-profile-confirm" />
  );
};

export default EidEasyWidget;
