import React from 'react';
import { Layout } from 'antd';
import './MainLayout.scss';
import config from 'config';
import CustomHeader from 'views/components/Header/Header';
import { useTranslation } from 'react-i18next';

const { Content, Footer } = Layout;

const MainLayout: React.FC = ({ children }) => {
  const { t }: { t: any } = useTranslation();
  return (
    <div id="MainLayout">
      <CustomHeader />
      <Layout>
        <Layout className="site-layout">
          <Content>{children}</Content>
          <Footer style={{ textAlign: 'center' }}>
            {t('footer.logo')}
            {new Date().getFullYear()}
            {' '}
            {t('footer.text')}
          </Footer>
        </Layout>
      </Layout>
      <div className="version">{`${config.projectCommitDate} ${config.projectCommitShortSHA}`}</div>
    </div>
  );
};
export default MainLayout;
