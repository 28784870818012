/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Spin, Steps,
} from 'antd';

import './StatusHistory.scss';
import { getPrmStatusHistoryService } from 'services/prm';
import { transformDate } from 'utils';
import { IPrmStatus } from 'types/prm';

const { Step } = Steps;

const StatusHistory = (
  { prmId }: { prmId: string },
) => {
  const { t }: { t: any } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [prmStatusHistory, setPrmStatusHistory] = useState<IPrmStatus[]>();

  const fetchPrmStatusHistory = async () => {
    setLoading(true);
    const prmStatusHistoryResponse = await getPrmStatusHistoryService(prmId);
    setLoading(false);
    setPrmStatusHistory(prmStatusHistoryResponse);
  };

  useEffect(() => {
    fetchPrmStatusHistory();
  }, [prmId]);

  return loading
    ? (<Spin style={{ margin: '0 auto', display: 'block' }} />)
    : (
      <div id="StatusHistoryModal">
        <Steps progressDot current={prmStatusHistory?.length} direction="vertical">
          {
            prmStatusHistory?.map((item) => (
              <Step
                title={t(`payment.status.${item.status}`)}
                subTitle={transformDate(
                  item.timestamp,
                  {
                    showYear: true,
                    showSeconds: true,
                  },
                )}
                description={
                  item.details
                    ? <a href={item.details}>{item.details}</a>
                    : ''
                }
              />
            ))
          }
        </Steps>
      </div>
    );
};

export default StatusHistory;
