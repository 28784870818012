/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import {
  Button,
  Steps,
  message,
} from 'antd';

import './RtpMessagesHistory.scss';
import { downloadBlobFile, transformDate } from 'utils';
import { getRtpMessageService } from 'services/rtp';

const { Step } = Steps;

type RtpMessages = {
  [key: string] : any
};

const RtpMessagesHistory = (
  { rtpMessages }: { rtpMessages: RtpMessages | undefined },
) => {
  const [rtpIsDownloading, setRtpIsDownloading] = useState<boolean>(false);

  const downloadRtpMessageXML = async (filename: string, messageId: string) => {
    setRtpIsDownloading(true);
    const [fileData, fileName] = await getRtpMessageService(filename, messageId);
    if (fileData && fileName) downloadBlobFile(fileData, fileName);
    else message.error('Some error occurred during download');
    setRtpIsDownloading(false);
  };

  return (
    rtpMessages ? (
      Object.keys(rtpMessages).length
        ? (
          <div id="StatusHistoryModal">
            <Steps progressDot current={Object.keys(rtpMessages).length} direction="vertical">
              {
            Object.keys(rtpMessages).map((key) => (
              <Step
                title={(
                  <Button
                    disabled={rtpIsDownloading}
                    onClick={() => downloadRtpMessageXML(key, rtpMessages[key][0].messageId)}
                  >
                    {key}
                  </Button>
                )}
                subTitle={transformDate(
                  rtpMessages[key][0].lastModified,
                  {
                    showYear: true,
                    showSeconds: true,
                  },
                )}
                // description={
                //   item.details
                //     ? <a href={item.details}>{item.details}</a>
                //     : ''
                // }
              />
            ))
          }
            </Steps>
          </div>
        )
        : <span>No RTP messages found </span>
    )
      : <span>Some error occurred</span>
  );
};
export default RtpMessagesHistory;
