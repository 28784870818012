import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React from 'react';
import CollapsableSection from '../CollapsableSection/CollapsableSection';
import styles from './PaymentSettings.module.scss';

const PaymentConfig = () => (
  <CollapsableSection
    title="Account payment configuration settings"
    className={styles.collapse}
  >
    <h2>Keving config</h2>
    <div className={styles.paymentConfig}>
      <FormItem
        name="kevinClientId"
        label="Client ID"
      >
        <Input placeholder="Type your client ID" />
      </FormItem>

      <FormItem
        name="kevinClientSecret"
        label="Client Secret"
      >
        <Input
          placeholder="Type your client secret"
        />
      </FormItem>

      <FormItem
        name="kevinIban"
        label="IBAN"
      >
        <Input placeholder="Type your IBAN" />
      </FormItem>

      <FormItem
        name="kevinName"
        label="Name"
      >
        <Input placeholder="Type your name" />
      </FormItem>

      <FormItem
        name="kevinRedirectUrl"
        label="Redirect URL"
      >
        <Input placeholder="Type your redirect URL" />
      </FormItem>
    </div>
    <h2>Montonio config</h2>
    <div className={styles.paymentConfig}>
      <FormItem
        name="montonioClientKey"
        label="Client Key"
      >
        <Input placeholder="Type your client ID" />
      </FormItem>

      <FormItem
        name="montonioClientSecret"
        label="Client Secret"
      >
        <Input
          placeholder="Type your client secret"
        />
      </FormItem>

      <FormItem
        name="montonioName"
        label="Name"
      >
        <Input placeholder="Type your name" />
      </FormItem>

      <FormItem
        name="montonioRedirectUrl"
        label="Redirect URL"
      >
        <Input placeholder="Type your redirect URL" />
      </FormItem>
    </div>
  </CollapsableSection>
);

export default PaymentConfig;
