import apiGateway from 'apis/api-gateway';
import {
  NewApiToken,
} from 'types';

import { IPrm, IPrmStatus } from 'types/prm';
import {
  IInitPrmRequest,
  IGetPrmResponse,
  IPrmInitResponse,
  IGeneratePrmRequest,
  IGeneratePrmResponseRequest,
  INotifyPayerRequest,
  GetApiTokensListResponse,
} from 'types/requests';

import { MIMEtoFormatMapper } from 'utils';

export const getPrmService = async (
  prmId: string,
): Promise<IGetPrmResponse> => {
  const prmResponse = await apiGateway.getPrm(prmId);
  return prmResponse.data;
};

export const getPrmStatusHistoryService = async (
  prmId: string,
): Promise<IPrmStatus[]> => {
  const prmStatusHistoryResponse = await apiGateway.getPrmStatusHistory(prmId);
  return prmStatusHistoryResponse.data;
};

export const getPrmStatusService = async (
  prmId: string,
): Promise<IPrmStatus> => {
  const prmStatusResponse = await apiGateway.getPrmStatus(prmId);
  return prmStatusResponse.data;
};

export const getPrmListService = async (
  search: undefined | string = undefined,
): Promise<IPrm[]> => {
  const prmListResponse = await apiGateway.getPrmList(search);
  return prmListResponse.data.prms;
};

export const getPrmIdService = async (
  prmIdRequest: IGeneratePrmRequest,
): Promise<string> => {
  const prmIdResponse = await apiGateway.getPrmId(prmIdRequest);
  return prmIdResponse.data.id;
};

export const getPrmQrCodeService = async (
  prmId: string,
): Promise<string> => {
  const prmQrCodeResponse = await apiGateway.getPrmQrCode(prmId);
  const blob = new Blob(
    [prmQrCodeResponse.data],
    { type: prmQrCodeResponse.headers['content-type'] },
  );
  return URL.createObjectURL(blob);
};

export const getPrmDocumentService = async (
  prmId: string,
): Promise<[Blob, string]> => {
  const prmDocumentResponse = await apiGateway.getPrmDocument(prmId);
  const contentDisposition = prmDocumentResponse.headers['content-disposition'];
  const filetype = MIMEtoFormatMapper[(prmDocumentResponse.headers['content-type'] as string)
    .split(';')[0]];
  const filename = contentDisposition.match(/filename=([^\s;]+)/)[1];
  const blob = new Blob(
    [prmDocumentResponse.data],
    { type: prmDocumentResponse.headers['content-type'] },
  );
  return [blob, filetype ? `${filename}.${filetype}` : ''];
};

export const initiatePrmService = async (
  initiatePrmParams: IInitPrmRequest,
): Promise<IPrmInitResponse> => {
  const prmInitResponse = await apiGateway.initiatePrm(initiatePrmParams);
  return prmInitResponse.data;
};

export const generatePrmResponseService = async (
  generatePrmResponseParams: IGeneratePrmResponseRequest,
): Promise<any> => {
  const prmResponse = await apiGateway.generatePrmResponse(generatePrmResponseParams);
  return prmResponse.data;
};

export const notifyPayerService = async (
  notifyPayerRequest: INotifyPayerRequest,
): Promise<any> => {
  const apiResponse = await apiGateway.notifyPayer(notifyPayerRequest);
  return apiResponse.data;
};

export const getApiTokensListService = async ()
: Promise<GetApiTokensListResponse> => {
  const getApiTokenListResponse = await apiGateway.getApiTokensList();
  return getApiTokenListResponse.data;
};

export const generateApiTokenService = async (
  tokenName: string,
): Promise<NewApiToken> => {
  const generateApiTokenListResponse = await apiGateway.generateApiToken(tokenName);
  return generateApiTokenListResponse.data;
};

export const updateApiTokenService = async (
  tokenId: string,
  name?: string,
  paused?: boolean,
): Promise<any> => {
  const updateApiTokenResponse = await apiGateway.updateApiToken(
    tokenId,
    name,
    paused,
  );
  return updateApiTokenResponse.data;
};

export const deleteApiTokenService = async (
  tokenId: string,
): Promise<any> => {
  const deleteApiTokenResponse = await apiGateway.deleteApiToken(tokenId);
  return deleteApiTokenResponse.data;
};
