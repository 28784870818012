/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import { Select } from 'antd';
import Search from 'antd/lib/input/Search';

import { useTranslation } from 'react-i18next';

import './SearchPanel.scss';

type SearchPanelProps = {
  filterPRMs: (search: string) => void,
};

const { Option } = Select;

const SearchPanel = (props: SearchPanelProps) => {
  const { t } = useTranslation();

  const {
    filterPRMs,
  } = props;

  const [searchFieldValue, setSearchFieldValue] = useState('');

  const handleSearch = () => {
    if (searchFieldValue.length) {
      filterPRMs(searchFieldValue);
    } else {
      filterPRMs('');
    }
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearchFieldValue(searchValue);
  };

  return (
    <div className="documentSearch">
      <Search
        placeholder={t('paymentsPage.searchPanel.placeholder')}
        name="documentFingerprint"
        onSearch={handleSearch}
        onChange={handleSearchInputChange}
        enterButton
      />
    </div>
  );
};
export default SearchPanel;
