/* eslint-disable import/prefer-default-export */
import rtpApi from 'apis/rtp';
import apiGateway from 'apis/api-gateway';
import { MIMEtoFormatMapper } from 'utils';

export const getRtpMessageService = async (
  dsName: string,
  messageId: string,
): Promise<[Blob, string]> => {
  const rtpMessageResponse = await rtpApi.getRtpMessage(messageId);
  const filetype = MIMEtoFormatMapper[(rtpMessageResponse.headers['content-type'] as string)
    .split(';')[0]];
  const blob = new Blob(
    [rtpMessageResponse.data],
    { type: rtpMessageResponse.headers['content-type'] },
  );
  return [blob, filetype ? `${dsName}.${filetype}` : ''];
};

export const getRtpMessagesListService = async (
  prmId: string,
): Promise<any> => {
  const apiResponse = await apiGateway.getRtpMessagesList(prmId);
  return apiResponse.data;
};
