/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import {
  Tooltip, Table, Button,
} from 'antd';

import {
  EditOutlined,
  PlayCircleOutlined,
  PauseOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import { ApiToken } from 'types';
import { transformDate } from 'utils';

type ApiTokensTableProps = {
  items: ApiToken[];
  loading: boolean;
  deleteButtonsBlocked: boolean;
  editNameButtonsBlocked: boolean;
  changeStatusButtonsBlocked: boolean;

  pauseToken: (id: string) => void;
  resumeToken: (id: string) => void;
  showDeleteTokenModal: (id: string) => void;
  showEditTokenNameModal: (id: string) => void;
};

const ApiTokensTable = ({
  items,
  loading,
  deleteButtonsBlocked,
  editNameButtonsBlocked,
  changeStatusButtonsBlocked,
  pauseToken,
  resumeToken,
  showDeleteTokenModal,
  showEditTokenNameModal,
}: ApiTokensTableProps) => {
  const columns: any = [{
    title: 'Name',
    key: 'name',
    width: 275,
    render: (rowProps: ApiToken) => (
      <span>
        <div>{rowProps.name}</div>
        <div>{rowProps.paused ? 'Paused' : 'Active'}</div>
      </span>
    ),
    sorter:
        (a: ApiToken, b: ApiToken) => (new Date(a.createdAt)).getTime() - (new Date(b.createdAt)).getTime(),
  },
  {
    title: 'Created at',
    key: 'createdAt',
    width: 180,
    responsive: ['sm'],
    render: (rowProps: ApiToken) => (
      <span>
        <div>{transformDate(rowProps.createdAt)}</div>
      </span>
    ),
    sorter:
        (a: ApiToken, b: ApiToken) => a.name.localeCompare(b.name),
  },
  {
    title: 'Actions',
    key: 'createdAt',
    width: 150,
    render: (rowProps: ApiToken) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        <Tooltip
          placement="bottom"
          title="Edit name"
        >
          <Button
            shape="circle"
            type="primary"
            disabled={editNameButtonsBlocked}
            onClick={() => showEditTokenNameModal(rowProps.id)}
            icon={<EditOutlined />}
          />
        </Tooltip>
        <Tooltip
          placement="bottom"
          title={rowProps.paused ? 'Resume' : 'Pause'}
        >
          <Button
            shape="circle"
            type="primary"
            disabled={changeStatusButtonsBlocked}
            icon={rowProps.paused ? <PlayCircleOutlined /> : <PauseOutlined />}
            onClick={() => (rowProps.paused ? resumeToken(rowProps.id) : pauseToken(rowProps.id))}
          />
        </Tooltip>
        <Tooltip
          placement="bottom"
          title="Delete"
        >
          <Button
            danger
            shape="circle"
            type="primary"
            disabled={deleteButtonsBlocked}
            onClick={() => showDeleteTokenModal(rowProps.id)}
            icon={<DeleteOutlined />}
          />
        </Tooltip>
      </div>
    ),
  }];

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={items}
      pagination={false}
      className="card-table"
      // showSorterTooltip={false}
      locale={{
        triggerDesc: 'descend sort text',
        triggerAsc: 'ascend sort text',
        cancelSort: 'cancel sort text',
      }}
      rowClassName="card-table-row"
      rowKey={(record: ApiToken) => record.id}
    />
  );
};

export default ApiTokensTable;
