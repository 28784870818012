export enum ActionTypes {
  GET_BREADCRUMBS = '@@breadcrumbs/GET_BREADCRUMBS',
  CLEAR_BREADCRUMBS = '@@breadcrumbs/CLEAR_BREADCRUMBS',
}

export interface BreadcrumbsAction {
  type: ActionTypes;
  breadcrumbs?: object
}

export interface BreadcrumbsState {
  readonly breadcrumbs? : object
}
