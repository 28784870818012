import prmApi from 'apis/prm';
import rtpApi from 'apis/rtp';
import apiGateway from 'apis/api-gateway';
import { ApplicationState } from 'modules';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { UserState } from 'redux-oidc';

const mapStateToProps = (state: ApplicationState): UserState => ({ ...state.oidc });

// const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IPrivateRoute {
  path: string;
  render?: any;
  component?: any;
  exact?: boolean;
  children?: any;
}
type Props = PropsFromRedux & IPrivateRoute;

const PrivateRoute = (props: Props) => {
  const { isLoadingUser, user, ...restProps } = props;
  if (!user) {
    localStorage.setItem('login_redirect', window.location.pathname + window.location.search);
    return <Redirect to={`/login?redirect=${window.location.pathname}${window.location.search}`} />;
  }
  prmApi.setAccessToken(user?.access_token);
  rtpApi.setAccessToken(user?.access_token);
  apiGateway.setAccessToken(user?.access_token);
  // ! destructure the restProps
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route exact {...restProps} />;
};

PrivateRoute.defaultProps = {
  exact: false,
  children: undefined,
  component: undefined,
  render: undefined,
};

export default connector(PrivateRoute);
