import React from 'react';
import { Badge } from 'antd';
import { statusToColorMapper } from 'utils';
import './PaymentStatusBadge.scss';

const PaymentStatusBadge = ({
  status,
}: { status: string }) => (
  <div
    className="payment-status-badge"
  >
    <Badge
      color={statusToColorMapper[status]}
    />
  </div>
);

export default PaymentStatusBadge;
