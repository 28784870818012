import PasswordValidator from 'password-validator';

const passwordValidator = new PasswordValidator();

passwordValidator
  .is().min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits(1)
  .has()
  .not()
  .spaces()
  .has()
  .symbols();

export default passwordValidator;
