import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as oidcReducer } from 'redux-oidc';
import breadcrumbs from 'modules/breadcrumbs/reducer';
import userManager from 'modules/userManager/reducer';
import history from 'history';

const createRootReducer = (historyValue: history.History) => combineReducers({
  router: connectRouter(historyValue),
  breadcrumbs,
  userManager,
  oidc: oidcReducer,
});

export default createRootReducer;
