import React from 'react';
import { Modal } from 'antd';

import StatusHistory from 'views/components/StatusHistory/StatusHistory';

type StatusHistoryModalProps = {
  closeModal: () => void;
  selectedPrmId: string;
  statusHistoryModalVisible: boolean
};

const StatusHistoryModal = ({
  closeModal,
  selectedPrmId,
  statusHistoryModalVisible,
}: StatusHistoryModalProps) => (
  <Modal
    visible={statusHistoryModalVisible}
    onCancel={closeModal}
    footer={null}
  >
    <StatusHistory
      prmId={selectedPrmId}
    />
  </Modal>
);

export default StatusHistoryModal;
