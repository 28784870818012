import { IUser } from 'types/user';

export enum ActionTypes {
  STORE_USER = '@@usermanager/STORE_USER',
  REMOVE_USER = '@@usermanager/REMOVE_USER',
}

export interface UserManagerAction {
  type: ActionTypes,
  user?: IUser,
  error?: Error,
}

export interface UserManagerState {
  readonly error?: Error;
  readonly user?: IUser;
}
