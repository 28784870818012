/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Form,
  Input,
  Button,
  Divider,
  message,
} from 'antd';

import { transformDate, useQuery } from 'utils';
import { generatePrmResponseService, getPrmService, getPrmListService } from 'services/prm';

import './RtpReceivePage.scss';
import { IPrm } from 'types/prm';

const RtpReceivePage = () => {
  const queryParams = useQuery();
  const { t }: { t: any } = useTranslation();
  const [prmIdParam, setPrmIdParam] = useState<string>('');
  const [prmIdValue, setPrmIdValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>();
  const [prm, setPrm] = useState<IPrm>();

  const fetchPrmInfo = async (prmId: string) => {
    setLoading(true);
    setPrm(undefined);
    message.loading({
      content: 'loading...',
      key: 'loading',
    });
    try {
      const prmListResponse = await getPrmListService(prmId);
      if (prmListResponse.length) {
        setPrm(prmListResponse[0]);
        message.loading({
          content: 'Payment already exists, redirecting to the main page...',
          key: 'redirect',
        }, 2.5);
        setTimeout(() => {
          window.location.assign('/board/payments');
        }, 3000);
      } else {
        const prmResponse = await getPrmService(prmId);
        setPrm(prmResponse);
      }
    } catch (error: any) {
      message.error(error.response.data.message
        || (typeof error.response.data.data === 'string' ? error.response.data.data : null)
        || error.response.data.error.description
        || error.message, 1.5);
    } finally {
      setLoading(false);
      message.destroy('loading');
    }
  };

  const receivePrm = async (prmId: string) => {
    setLoading(true);
    message.loading({
      content: 'loading...',
      key: 'loading',
    });
    if (prm?.status.status !== 'received') {
      try {
        const prmReceiveResponse = await generatePrmResponseService({
          prmId,
          status: 'receive',
          notes: (new Date()).toLocaleString(),
        });
        if (prmReceiveResponse.status && prm) {
          setPrm({
            ...prm,
            status: {
              status: prmReceiveResponse.status,
              timestamp: prmReceiveResponse.timestamp,
            },
          });
        } else if (prmReceiveResponse.status && !prm) {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const prmResponse = await getPrmService(prmId);
          setPrm(prmResponse);
        } else {
          message.error('Some error occurred, try later', 2.5);
        }
      } catch (error: any) {
        message.error(error.response.data.message
        || (typeof error.response.data.data === 'string' ? error.response.data.data : null)
        || error.response.data.error.description
        || error.message, 1.5);
      } finally {
        setLoading(false);
        message.destroy('loading');
      }
    } else {
      setLoading(false);
      message.destroy('loading');
      message.warning('The prm status is already received', 1);
    }
  };

  const acceptPrm = async (prmId: string) => {
    setLoading(true);
    message.loading({
      content: 'loading...',
      key: 'loading',
    });
    if (prm?.status.status !== 'accepted') {
      try {
        const prmReceiveResponse = await generatePrmResponseService({
          prmId,
          status: 'accept',
          notes: (new Date()).toLocaleString(),
        });
        if (prmReceiveResponse.status && prm) {
          setPrm({
            ...prm,
            status: {
              status: prmReceiveResponse.status,
              timestamp: prmReceiveResponse.timestamp,
            },
          });
        } else if (prmReceiveResponse.status && !prm) {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const prmResponse = await getPrmService(prmId);
          setPrm(prmResponse);
        } else {
          message.error('Some error occurred, try later', 2.5);
        }
      } catch (error: any) {
        message.error(error.response.data.message
        || (typeof error.response.data.data === 'string' ? error.response.data.data : null)
        || error.response.data.error.description
        || error.message, 1.5);
      } finally {
        setLoading(false);
        message.destroy('loading');
      }
    } else {
      setLoading(false);
      message.destroy('loading');
      message.warning('The prm status is already accepted', 1);
    }
  };

  const rejectPrm = async (prmId: string) => {
    setLoading(true);
    message.loading({
      content: 'loading...',
      key: 'loading',
    });
    if (prm?.status.status !== 'rejected') {
      try {
        const prmReceiveResponse = await generatePrmResponseService({
          prmId,
          status: 'reject',
          notes: (new Date()).toLocaleString(),
        });
        if (prmReceiveResponse.status && prm) {
          setPrm({
            ...prm,
            status: {
              status: prmReceiveResponse.status,
              timestamp: prmReceiveResponse.timestamp,
            },
          });
        } else if (prmReceiveResponse.status && !prm) {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const prmResponse = await getPrmService(prmId);
          setPrm(prmResponse);
        } else {
          message.error('Some error occurred, try later', 2.5);
        }
      } catch (error: any) {
        message.error(error.response.data.message
        || (typeof error.response.data.data === 'string' ? error.response.data.data : null)
        || error.response.data.error.description
        || error.message, 1.5);
      } finally {
        setLoading(false);
        setPrmIdParam('');
        message.destroy('loading');
      }
    } else {
      setLoading(false);
      message.destroy('loading');
      message.warning('The prm status is already rejected', 1);
    }
  };

  useEffect(() => {
    const prmId = (queryParams.get('prmid') || '').replace('*', '');
    setPrmIdParam(prmId);
    if (prmId) fetchPrmInfo(prmId);
  }, []);

  const prmIdForm = (
    <div className="main-email">
      <Form
        className="card card-email"
        layout="vertical"
        hideRequiredMark
        onKeyUp={(e) => { if (e.key === 'Enter') fetchPrmInfo(prmIdValue); }}
      >
        <p className="caption caption__light-grey caption-500 card-heading">
          {/* {t('signupPage.confirmEmailPage.mainHeader')} */}
          Receive RTP
        </p>
        <Divider className="divider" />
        <div className="card-body">
          <Form.Item
              // name="email1"
            label="Enter prm Id"
            className="with-help"
            help={(
              <span>
                For example: 13033f35579340278dba8b4ae0bb90c0
              </span>
                )}
          >
            <Input
              disabled={loading}
              onChange={(e) => { setPrmIdValue(e.target.value); }}
            />
          </Form.Item>
        </div>
      </Form>
      <Button
        type="primary"
        disabled={loading}
        className="button-round button-round__primary"
        onClick={() => fetchPrmInfo(prmIdValue)}
      >
        Initiate payment
      </Button>
    </div>
  );
  // ! if prmIdParam - start receiving, set loading true
  // ! if receiving is successful -- loading false,
  // ! show prm is successfully received, what do you want to do with the payment + prm info,
  // ! if receiving has error -- loading false, show prmIdForm
  // ! after click form - block fields

  const loadingSkeleton = <div />;

  // (prmIdParam && loading) => loadingSkeleton
  // (prm) => show prm info + what to do next
  // (!prmIdParam) => showform
  // (prmIdValue && loading) => block the form

  return (
    <div id="RtpReceivePage">
      {
        (prmIdParam || loading) ? loadingSkeleton : false
      }
      {
        (!prmIdParam && !prm) ? prmIdForm : false
      }
      {
        prm
          ? (
            <div>
              <span>Here is your prm, what to do next?</span>
              <ul>
                <li>{`Amount: ${prm.amount} ${prm.currency}`}</li>
                <li>{`Description: ${prm.description}`}</li>
                <li>{`Id: ${prm.id}`}</li>
                <li>{`Order Id: ${prm.orderId}`}</li>
                <li>{`Created at: ${transformDate(prm.createdAt)}`}</li>
                <li>{`Payee name: ${prm.payee.name}`}</li>
                <li>{`Payee email: ${prm.payee.email}`}</li>
                <li>{`Prm status: ${prm.status.status}`}</li>
              </ul>
              <Button disabled={false} type="primary" danger onClick={() => rejectPrm(prmIdValue || prmIdParam)}>Reject</Button>
              <Button disabled={false} type="primary" onClick={() => acceptPrm(prmIdValue || prmIdParam)}>Accept</Button>
              <Button disabled={false} type="primary" onClick={() => receivePrm(prmIdValue || prmIdParam)}>Receive</Button>
              {/* <Button disabled type="primary">Pay later</Button> */}
              {/* <Button disabled type="primary">Pay now</Button> */}
              <Button type="primary" onClick={() => setPrm(undefined)}>Receive new payment</Button>
            </div>
          )
          : false
      }
    </div>
  );
};

export default RtpReceivePage;
