import React from 'react';
import { Modal } from 'antd';
import PaymentQrCode, { PaymentQrCodeDetails } from 'views/components/PaymentQrCode/PaymentQrCode';

type QrCodeModalProps = {
  closeModal: () => void;
  title?: string,
  prmId: string,
  qrCodeError: boolean;
  qrCodeLoading: boolean;
  qrModalVisible: boolean;
  paymentDetails?: PaymentQrCodeDetails
};

const QrCodeModal = ({
  title,
  closeModal,
  qrCodeError,
  prmId,
  qrCodeLoading,
  qrModalVisible,
  paymentDetails,
}: QrCodeModalProps) => (
  <Modal
    bodyStyle={{ padding: '10px 24px' }}
    title={title}
    width={350}
    visible={qrModalVisible}
    footer={false}
    onCancel={closeModal}
  >
    { (!qrCodeError && !qrCodeLoading) ? (
      <PaymentQrCode
        prmId={prmId}
        expanded
        paymentDetails={paymentDetails}
      />
    ) : false}
  </Modal>
);

QrCodeModal.defaultProps = {
  title: 'Payment Qr code',
  paymentDetails: {
    amount: '...',
    status: '...',
    orderId: '...',
    currency: '...',
    createdAt: '...',
    payeeName: '...',
    payeeEmail: '...',
  },
};

export default QrCodeModal;
