import { AxiosResponse } from 'axios';
import {
  NewApiToken,
} from 'types';
import { IPrmStatus } from 'types/prm';
import { UserRepresentation } from 'types/user';
import {
  IInitPrmRequest,
  IGetPrmResponse,
  IPrmInitResponse,
  IGeneratePrmRequest,
  IGeneratePrmResponseRequest,
  IGetPrmListResponse,
  INotifyPayerRequest,
  GetApiTokensListResponse,
  Account,
} from 'types/requests';
import {
  instance,
  setAccessToken,
} from './instance';

const confirmUserWithEid = (
  currentEmail : string,
  userData: UserRepresentation,
  recaptcha: string,
): Promise<AxiosResponse<any>> => instance
  .put<any>('/users/confirm', { currentEmail, userData }, { params: { recaptcha } });

const getPrm = (
  prmId: string,
): Promise<AxiosResponse<IGetPrmResponse>> => instance.get(`/prm/${prmId}`);

const getPrmList = (
  search: undefined | string = undefined,
): Promise<AxiosResponse<IGetPrmListResponse>> => instance.get(
  '/prm',
  {
    params: {
      search: search || undefined,
    },
  },
);

const getPrmId = (
  prmIdRequest: IGeneratePrmRequest,
): Promise<AxiosResponse<any>> => instance.post('/prm', prmIdRequest);

const getPrmQrCode = (
  prmId: string,
): Promise<AxiosResponse<string>> => instance.get(`/prm/${prmId}/qr`, { responseType: 'arraybuffer' });

const getPrmStatus = (
  prmId: string,
): Promise<AxiosResponse<IPrmStatus>> => instance.get(`/prm/${prmId}/status`, { params: { history: false } });

const getPrmStatusHistory = (
  prmId: string,
): Promise<AxiosResponse<IPrmStatus[]>> => instance.get(`/prm/${prmId}/status`, { params: { history: true } });

const getPrmDocument = (
  prmId: string,
): Promise<AxiosResponse<string>> => instance.get(`/prm/${prmId}/document`, { responseType: 'arraybuffer' });

const initiatePrm = (
  {
    prmId,
    bankId,
    kevinAccessToken,
    kevinRedirectUrl,
    gateway,
  }: IInitPrmRequest,
): Promise<AxiosResponse<IPrmInitResponse>> => {
  const headers = {
    'access-token': kevinAccessToken || '',
    'redirect-url': kevinRedirectUrl || '',
  };
  return instance.post(
    `/prm/${prmId}/init`,
    {},
    {
      params: { bankId, gateway },
      headers,
    },
  );
};

const generatePrmResponse = (
  {
    prmId,
    status,
    notes,
  }: IGeneratePrmResponseRequest,
): Promise<AxiosResponse<any>> => instance.post(`/prm/${prmId}/response`, {
  status,
  notes,
});

const notifyPayer = ({
  prmId,
  recipient,
}: INotifyPayerRequest): Promise<AxiosResponse<any>> => instance.post(`/prm/${prmId}/invite`, {
  recipient,
});

const getRtpMessagesList = (
  prmId: string,
): Promise<AxiosResponse<any>> => instance.get(`/prm/${prmId}/rtp`);

const getApiTokensList = ()
: Promise<AxiosResponse<GetApiTokensListResponse>> => (
  instance.get('/tokens')
);

const generateApiToken = (
  tokenName: string,
): Promise<AxiosResponse<NewApiToken>> => (
  instance.post('/tokens', {
    name: tokenName,
    services: ['corposign-pay-api-token'],
  })
);

const updateApiToken = (
  tokenId: string,
  name?: string,
  paused?: boolean,
): Promise<AxiosResponse<any>> => (
  instance.put(`/tokens/${tokenId}`, {
    name,
    paused,
  })
);

const deleteApiToken = (
  tokenId: string,
): Promise<AxiosResponse<any>> => (
  instance.delete(`/tokens/${tokenId}`)
);

export const getAccount = async () => {
  try {
    const account = await instance.get<Account>('/account');
    return account;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const putAccount = async (acc: Account) => {
  try {
    await instance.put('/account', acc);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default {
  getPrm,
  getPrmId,
  getPrmList,
  initiatePrm,
  getPrmQrCode,
  getPrmDocument,
  getPrmStatus,
  setAccessToken,
  generatePrmResponse,
  getPrmStatusHistory,
  notifyPayer,
  getRtpMessagesList,
  getApiTokensList,
  generateApiToken,
  updateApiToken,
  deleteApiToken,
  confirmUserWithEid,
};
