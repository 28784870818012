import axios from 'axios';
import config from '../../config';

export const instance = axios.create({
  baseURL: config.prmApiUrl,
});

export const setAccessToken = (token?: string) => {
  if (token) {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete instance.defaults.headers.common.Authorization;
  }
};

export const setAcceptLanguageHeader = (language: string) => {
  instance.defaults.headers.common['Accept-Language'] = language;
};
