const viewNames = {
  MethodSelection: 'MethodSelection',
  SmartIdAuth: 'SmartIdAuth',
  MobileIdAuth: 'MobileIdAuth',
  IdentificationFrejaId: 'IdentificationFrejaId',
  IdentificationZealId: 'IdentificationZealId',
  SignatureSmartId: 'SignatureSmartId',
  SignatureMobileId: 'SignatureMobileId',
  SignatureFtn: 'SignatureFtn',
  SignatureOtp: 'SignatureOtp',
  SignatureEvrotrust: 'SignatureEvrotrust',
  SignatureDTrustSignMe: 'SignatureDTrustSignMe',
};

export default viewNames;
