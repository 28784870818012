/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { getPrmQrCodeService, getPrmService, getPrmStatusService } from 'services/prm';
import { setAccessToken } from 'apis/prm/instance';

import { useQuery } from 'utils';

import './ShowPrmQrPage.scss';
import {
  Spin,
} from 'antd';
import { decodeJWS } from 'utils/jws';

import PaymentQrCode from 'views/components/PaymentQrCode/PaymentQrCode';

const ShowPrmQrPage = () => {
  const queryParams = useQuery();

  const [amount, setAmount] = useState<number>(0);
  const [orderId, setOrderId] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [currency, setCurrency] = useState<string>('');
  const [payeeName, setPayeeName] = useState<string>('');
  const [createdAt, setCreatedAt] = useState<string>('');
  const [payerEmail, setPayerEmail] = useState<string>();
  const [payeeEmail, setPayeeEmail] = useState<string>('');

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [prmStatusChanged, setPrmStatusChanged] = useState<boolean>(false);
  const [tokenSecondsCountdown, setTokenSecondsCountdown] = useState<number>();
  const [qrCodeSecondsCountdown, setQrCodeSecondsCountdown] = useState<number>(15);

  const [expirationInterval, setExpirationInterval] = useState<any>();
  const [statusWatcherInterval, setStatusWatcherInterval] = useState<any>();

  const stopStatusWatcher = () => {
    setAccessToken('');
    clearInterval(expirationInterval);
    clearInterval(statusWatcherInterval);
    setExpirationInterval('');
    setStatusWatcherInterval('');
  };

  const runStatusWatcher = (prmId: string, initialStatus: string) => {
    setStatusWatcherInterval(setInterval(async () => {
      try {
        const fetchedStatus = (await getPrmStatusService(prmId)).status;
        if (fetchedStatus !== initialStatus) {
          stopStatusWatcher();
          setPrmStatusChanged(true);
        }
      } catch {
        setError(true);
        setErrorMessage('error during prm status fetch');
        // console.log('error during prm status fetch');
      }
    }, 3000));
  };

  const fetchPrmInfo = async (prmId: string) => {
    try {
      const prm = await getPrmService(prmId);

      setAmount(prm.amount);
      setOrderId(prm.orderId);
      setCurrency(prm.currency);
      setCreatedAt(prm.createdAt);
      setStatus(prm.status.status);
      setPayeeName(prm.payee.name);
      setPayeeEmail(prm.payee.email);
      setPayerEmail(prm.payer?.email);
    } catch {
      setError(true);
      setErrorMessage('error during prm data fetch');
      // console.log('error during prm data fetch');
    }
  };

  const processToken = (token: string) => {
    try {
      const decodedJWT = decodeJWS(token);
      setTokenSecondsCountdown(decodedJWT.exp! - Math.trunc(Date.now() / 1000));
      setExpirationInterval(setInterval(() => {
        setTokenSecondsCountdown(decodedJWT.exp! - Math.trunc(Date.now() / 1000));
      }, 1000));
    } catch {
      setError(true);
      setErrorMessage('token is incorrect');
      // console.log('token is incorrect');
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (qrCodeSecondsCountdown >= 0) {
      setTimeout(() => setQrCodeSecondsCountdown(qrCodeSecondsCountdown - 1), 1000);
    } else {
      stopStatusWatcher();
      // console.log('timed out');
      setError(true);
      setErrorMessage('Qr code timedout');
    }
  }, [qrCodeSecondsCountdown]);

  useEffect(() => {
    const token = (queryParams.get('token') || '');
    const prmId = (queryParams.get('prmid') || '').replace('*', '');

    if (prmId && token) {
      if (processToken(token)) {
        (async () => {
          setLoading(true);
          setAccessToken(token);
          await fetchPrmInfo(prmId);
          const initialStatus = (await getPrmStatusService(prmId)).status;
          runStatusWatcher(prmId, initialStatus);

          setLoading(false);
        })();
      }
    } else {
      setError(true);
      setErrorMessage('token or prmId are not provided');
      // console.log('token or prmId are not provided');
    }
  }, []);

  return (
    <div id="ShowPrmQrPage">
      {
        loading ? <div style={{ textAlign: 'center' }}>loading</div> : false
      }
      {
        error ? <div style={{ textAlign: 'center' }}>{errorMessage}</div> : false
      }
      {
        prmStatusChanged ? <div style={{ textAlign: 'center' }}>Prm status changed</div> : false
      }
      {
        (!prmStatusChanged && !loading)
          ? (
            <>
              <Spin />
              <span style={{ textAlign: 'center' }}>{`The token will expire in: ${tokenSecondsCountdown}`}</span>
              <PaymentQrCode
                prmId={queryParams.get('prmid') || 'none'}
                expanded
                paymentDetails={{
                  amount,
                  status,
                  orderId,
                  currency,
                  createdAt,
                  payeeName,
                  payeeEmail,
                  payerEmail,
                }}
              />
              <span style={{ textAlign: 'center' }}>{`The QR code will expire in: ${qrCodeSecondsCountdown}`}</span>
            </>
          )
          : false
      }
    </div>
  );
};

export default ShowPrmQrPage;
