/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Avatar, Button, Divider } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { User } from 'oidc-client';

import styles from './UserData.module.scss';
import usePasswordModal from '../Modals/usePasswordModal';
import useIdentityModal from '../Modals/useIdentityModal';

const UserData = ({ user }: { user: User | undefined }) => {
  const { t } = useTranslation();
  const [openPassword, PasswordModal] = usePasswordModal(user?.profile.email || '');
  const [openIdentity, IdentityModal] = useIdentityModal(user?.profile.email || '');

  return (
    <section className={styles.userData}>
      <h2>Your Details</h2>
      <Avatar
        size={184}
        icon={user?.profile.picture || <UserOutlined />}
        className={styles.avatar}
      />
      <div className={styles.dataContainer}>
        <p>{t('profilePage.userData.fullName')}</p>
        <small>{`${user?.profile.given_name} ${user?.profile.family_name || ''}`}</small>

        <p>{t('profilePage.userData.email')}</p>
        <small>{user?.profile.email}</small>

        <p>{t('profilePage.userData.birthDate')}</p>
        <small>{user?.profile.birthdate}</small>

        <p>{t('profilePage.userData.nationalCodeSHA256')}</p>
        <small>{user?.profile.personal_identifier_sha256}</small>

        <p>{t('profilePage.userData.country')}</p>
        <small>{user?.profile.locale}</small>
        <Button onClick={openPassword}>
          Change Password
        </Button>
        {!user?.profile.is_verified && (
          <Button onClick={openIdentity}>
            Confirm Identity
          </Button>
        )}
      </div>

      <Divider className={styles.divider} />

      <div className={styles.positionData}>
        <p>Position</p>
        <small>TO DO</small>

        <p>Company name</p>
        <small>TO DO</small>

        <p>Company ID</p>
        <small>TO DO</small>

        <p>Authority ID</p>
        <small>TO DO</small>

        <p>Authority ICD</p>
        <small>TO DO</small>

        <p>Verification</p>
        <small>TO DO</small>

        <p>Certificate ID</p>
        <small>TO DO</small>

        <p>Certificate Validity</p>
        <small>TO DO</small>
      </div>
      {PasswordModal}
      {IdentityModal}
    </section>
  );
};

export default UserData;
