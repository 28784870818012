import { Profile, UserManager, UserManagerSettings } from 'oidc-client';
import { createUserManager } from 'redux-oidc';
import config from 'config';
import { decodeJWS } from './jws';

const userManagerConfig: UserManagerSettings = {
  client_id: config.keycloak.clientId,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
  response_type: 'code',
  scope: 'openid profile',
  authority: config.keycloak.url,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  metadata: {
    userinfo_endpoint: config.oidc.userInfoUrl,
    issuer: config.oidc.user.iss,
    authorization_endpoint: `${config.oidc.user.iss}/protocol/openid-connect/auth`,
    token_endpoint: `${config.oidc.user.iss}/protocol/openid-connect/token`,
    jwks_uri: `${config.oidc.user.iss}/protocol/openid-connect/certs`,
    end_session_endpoint: `${config.oidc.user.iss}/protocol/openid-connect/logout`,
  },
};
// axios.get(
//   'https://api.dev.ino-pay.com/auth/v1/users/info',
//   {
//     withCredentials: true,
//     headers: {
//       'Access-Control-Allow-Credentials': true,
//       'Authorization': 'aaa',
//       Origin: '*',
//     },
//   },
// ).then(((response) => console.log(response))).catch(((err) => console.log(err)));
// userManager.storeUser()
interface CustomUserManager extends UserManager {
  refreshUser: () => Promise<void>;
}
const defaultUserManager = createUserManager(userManagerConfig);

const userManager: any = defaultUserManager;

userManager.refreshUser = async () => {
  const newUser = await userManager.signinSilent();
  const decodedToken = decodeJWS(newUser.access_token);
  newUser.profile = decodedToken as Profile;
  await userManager.storeUser(newUser);
};

export default userManager as CustomUserManager;
