const flags = {
  // example
  /*
  FF_FREJA_EID_LOGIN: {
    created: '2021-08-02',
    enabledInEnvs: ['development'],
  },
  */
};

const createFeatureFlags = function createFeatureFlags({
  env,
}) {
  const enabled = function enabled(flag) {
    let isEnabled = false;
    if (flags[flag] && flags[flag].enabledInEnvs.includes(env)) {
      isEnabled = true;
    }
    return isEnabled;
  };

  return {
    enabled,
  }
};

const featureFlags = createFeatureFlags({
  env: process.env.VUE_APP_ENV || 'production',
});

export default featureFlags;
