/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import {
  Button, Divider, Form, Input, Modal,
} from 'antd';

import { ModalProps } from 'types';

import CopyButton from 'views/components/CopyButton/CopyButton';
import BlockHeader from 'views/components/BlockHeader/BlockHeader';
import { DownloadOutlined } from '@ant-design/icons';

interface ShowNewTokenModalProps extends ModalProps {
  token: string;
  downloadToken: () => void;
}

export const ShowNewTokenModal = ({
  token,
  visible,
  closeModal,
  downloadToken,
}: ShowNewTokenModalProps) => (
  <Modal
    title="Success"
    visible={visible}
    onCancel={closeModal}
    footer={null}
  >
    <BlockHeader
      title="Save new API token"
    >
      <Button
        onClick={downloadToken}
        style={{ marginRight: '5px' }}
      >
        <DownloadOutlined />
        .txt
      </Button>
      <CopyButton
        title="Copy"
        copyText={token}
      />
    </BlockHeader>
    <Input.TextArea
      autoSize
      disabled
      value={token}
    />
    <p
      style={{
        color: 'red',
        borderTop: '1px solid grey',
        marginTop: '15px',
        paddingTop: '15px',
      }}
    >
      This token will not be shown anymore, save it now!
    </p>
  </Modal>
);

interface GenerateNewTokenModalProps extends ModalProps {
  loading: boolean;
  invalidTokenNames: string[],
  generateNewToken: (name: string) => void;
}

export const GenerateNewTokenModal = ({
  visible,
  loading,
  invalidTokenNames,
  closeModal,
  generateNewToken,
}: GenerateNewTokenModalProps) => {
  const [generateNewTokenForm] = Form.useForm();
  const [hasError, setHasError] = useState<boolean>(false);
  const handleCancel = () => {
    closeModal();
    generateNewTokenForm.resetFields();
  };
  return (
    <Modal
      title="Create new API token"
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button
          danger
          key="back"
          type="primary"
          form="editTokenForm"
          onClick={handleCancel}
          className="button-round"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          form="newTokenForm"
          loading={loading}
          className="button-round button-green"
        >
          Create
        </Button>,
      ]}
    >
      <Form
        hideRequiredMark
        id="newTokenForm"
        layout="horizontal"
        form={generateNewTokenForm}
        onFinish={(data: any) => {
          generateNewToken(data.tokenName);
          generateNewTokenForm.resetFields();
        }}
      >
        <Form.Item
          label="Token name"
          name="tokenName"
          className={hasError ? '' : 'no-help'}
          rules={[{ required: true },
            () => ({
              validator(_, value) {
                if (!invalidTokenNames.includes(value)) {
                  setHasError(false);
                  return Promise.resolve();
                }
                setHasError(true);
                return Promise.reject(new Error('Token with this name already exists!'));
              },
            })]}
        >
          <Input
            style={{ width: '100%' }}
            disabled={loading}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

interface EditTokenNameModalProps extends ModalProps {
  loading: boolean;
  invalidTokenNames: string[],
  editTokenName: (name: string) => void;
}

export const EditTokenNameModal = ({
  visible,
  loading,
  invalidTokenNames,
  closeModal,
  editTokenName,
}: EditTokenNameModalProps) => {
  const [editTokenNameForm] = Form.useForm();
  const [hasError, setHasError] = useState<boolean>(false);
  const handleCancel = () => {
    closeModal();
    editTokenNameForm.resetFields();
  };
  return (
    <Modal
      title="Edit API name"
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button
          danger
          key="back"
          type="primary"
          form="editTokenForm"
          onClick={handleCancel}
          className="button-round"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          form="editTokenForm"
          className="button-round button-green"
        >
          Edit
        </Button>,
      ]}
    >
      <Form
        hideRequiredMark
        layout="horizontal"
        id="editTokenForm"
        onFinish={(data: any) => {
          editTokenName(data.tokenName);
          editTokenNameForm.resetFields();
        }}
      >
        <Form.Item
          label="Token name"
          name="tokenName"
          className={hasError ? '' : 'no-help'}
          rules={[{ required: true },
            () => ({
              validator(_, value) {
                if (!invalidTokenNames.includes(value)) {
                  setHasError(false);
                  return Promise.resolve();
                }
                setHasError(true);
                return Promise.reject(new Error('Token with this name already exists!'));
              },
            })]}
        >
          <Input
            style={{ width: '100%' }}
            disabled={loading}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

interface DeleteTokenModalProps extends ModalProps {
  loading: boolean;
  deleteToken: () => void;
}

export const DeleteTokenModal = ({
  loading,
  visible,
  closeModal,
  deleteToken,
}: DeleteTokenModalProps) => (
  <Modal
    title="Confirm token deletion"
    visible={visible}
    onCancel={closeModal}
    footer={[
      <Button
        key="back"
        type="primary"
        form="editTokenForm"
        onClick={closeModal}
        className="button-round button-green"
      >
        Cancel
      </Button>,
      <Button
        danger
        key="submit"
        type="primary"
        htmlType="submit"
        loading={loading}
        onClick={deleteToken}
        className="button-round"
      >
        Delete
      </Button>,
    ]}
  >
    <span>Are you sure, that you want to delete this token?</span>
  </Modal>
);
